<div>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{data.heading}}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="close(false)"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <mat-checkbox (change)="toggleActiveRun($event)">
      {{data.screenerName}}
    </mat-checkbox>
    <p>{{data.paragraph}}</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary" (click)="close(true)"
    >
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div>{{data.acceptButtonText}}</div>
      </div>
    </button>

    <button type="button" class="btn btn-default" (click)="close(false)">
      {{data.cancelButtonText}}
    </button>
  </div>
</div>
