import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EsgScoringDataUtilities } from "../../helpers/esg-scoring-data-utilities";

@Component({
  selector: "esg-score-card",
  templateUrl: "./score-card.component.html",
  styleUrls: ["./score-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreCardComponent {
  @Input() title: string;
  @Input() score: number;
  @Input() secondaryText: string;
  @Input() colorHex: string;
  @Input() secondaryColorHex: string;
  @Input() benchmarkPercent: number;
  @Input() benchmarkText: string;
  @Input() showColorBar: boolean = true;
  @Input() quartile: number
  Array = Array;
  EsgScoringDataUtilities = EsgScoringDataUtilities;
}
