import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "esg-source-selector",
  templateUrl: "./source-selector.component.html",
  styleUrls: ["./source-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceSelectorComponent {
  @Input() sources: sourceVM[];
  @Output() sourceSelected = new EventEmitter<sourceVM>();
}

export class sourceVM {
  key: string;
  text: string;
  selected: boolean;
}
