<esg-app-header
  [backlinkText]="
    'CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY.PAGE_TITLE' | translate
  "
  [backlinkLocation]="'../'"
  [pageTitle]="'CURRENT_MODEL.PAGES.CUSTOMIZE.PAGE_TITLE' | translate"
></esg-app-header>

<ng-container *ngIf="viewModel$ | async as viewModel">
  <div fxLayout="row">
    <div fxFlex fxFlex.lt-lg="20px"></div>
    <div fxFlex.lt-lg fxFlex="1020px">
      <h1>
        {{ "CURRENT_MODEL.PAGES.CUSTOMIZE.HEADING" | translate }}
      </h1>

      <esg-financials
        [benchmarkingSelection]="viewModel.benchmarkingSelection"
        [peerCompanyCount]="viewModel.peerCompanyCount"
      ></esg-financials>

      <esg-individual-company-details></esg-individual-company-details>

      <esg-pillar-selector
        [pillars]="viewModel.pillars"
        (pillarSelected)="selectPillar($event.key)"
      ></esg-pillar-selector>

      <div
        fxLayout="row"
        fxLayout.lt-sm="column"
        fxLayoutAlign="space-between"
        fxLayoutGap="30px"
      >
        <div class="sources" fxFlex.gt-xs="100px" fxFlex fxFlex.gt-md="150px">
          <div class="sub-header">Sources</div>

          <esg-source-selector
            [sources]="viewModel.sources"
            (sourceSelected)="selectSource($event.key)"
          ></esg-source-selector>
        </div>

        <div fxFlex fxFlex.gt-sm="720px">
          <div fxLayout="row"
            fxLayoutAlign="space-between"
            fxLayoutGap="30px" class="sub-header">Metrics
            <div fxLayoutGap="10px" fxLayoutAlign="center center">
              <div class="purple-dot" fxLayoutAlign="center center" *ngIf="viewModel.showIndustryMessage"></div>
              <div class="industry-default-data" *ngIf="viewModel.showIndustryMessage">
                {{
                  "CURRENT_MODEL.GLOBAL.DEFAULT_INDUSTRY_MESSAGES.METRICS-SUB-HEADING" | translate
                }}
              </div>
            </div>
          </div> 
          <div class="metric-types">
            <esg-button-menu
              *ngIf="viewModel.metricTypes?.length > 1"
              [items]="viewModel.metricTypes"
              (itemSelected)="selectMetricType($event.key)"
            ></esg-button-menu>
            
            <esg-metric-items
              [items]="viewModel.items"
              (itemSelected)="toggleItem($event)"
            ></esg-metric-items>
          </div>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutGap="15px"
        fxLayoutAlign="end"
        style="margin-top: 30px"
      >
        <button class="btn" [routerLink]="'../executive-summary'">
          Cancel
        </button>
        
        <button
          [disabled]="(screenerSettingsChanged$ | async) === false"
          class="btn btn-primary"
          (click)="save()"
        >
          Save & Proceed
        </button>
      </div>
    </div>
    <div fxFlex fxFlex.lt-lg="20px"></div>
  </div>
</ng-container>
