import { EsgAppInsightsService } from 'src/app/services/esg-app-insights-service';
import { Injectable } from "@angular/core";
import { BenchmarkingSelection, ESGScoring, MetricType } from "../api/generated";
import { ScreenerSettings } from "../api/generated/ScreenerSettings";
import { ExistingScreener } from "../api/generated/ExistingScreener";
import { TranslateService } from "@ngx-translate/core";
import { ScreenerSettingsExistModalData } from "../shared/components/screener-settings-exist-modal/screener-settings-exist-modal-data";
import { EditScreenerModalData } from "../shared/components/edit-screener-modal/edit-screener-modal-data";
import {
  BehaviorSubject,
  EMPTY,
  exhaustMap,
  filter,
  from,
  map,
  Observable,
  of,
  repeat,
  Subject,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs";
import {
  ClientService,
  ModalService,
  RunService,
} from "@wtw/platform/services";
import { ApiResult } from "@wtw/platform/api";
import { ScreenerSettingsExistModalComponent } from "../shared/components/screener-settings-exist-modal/screener-settings-exist-modal.component";
import { IModalConfirmResult } from "@wtw/platform/interfaces";
import { EditScreenerModalComponent } from "../shared/components/edit-screener-modal/edit-screener-modal.component";
import { ScreenersProxy } from "../api/ScreenersController";
import { RunsProxy } from "@wtw/platform/api/proxies";
import { CreateRunModel } from "@wtw/platform/api/dtos";
import { AppInsightsEventAction } from "../shared/enums/app-insights-event-action";
import { AppInsightsEventCategory } from "../shared/enums/app-insights-event-category";
import { ComparisonCompany } from '../api/generated/ComparisonCompany';

@Injectable({
  providedIn: "root",
})
export class ScreenersService {
  customisationLocalisationKey = "CURRENT_MODEL.PAGES.CUSTOMIZE";

  constructor(
    private translateService: TranslateService,
    private runService: RunService,
    private screenerProxy: ScreenersProxy,
    private modalService: ModalService,
    private clientService: ClientService,
    private runProxy: RunsProxy,
    private esgAppInsightsService: EsgAppInsightsService
  ) {}

  uiSignal_SettingActiveScreener = this.getLocalizationKeyValue(".SETTING-ACTIVE-SCREENER-SPINNER-MESSAGE");
  uiSignal_SearchingForExistingScreener = this.getLocalizationKeyValue(".SEARCHING-EXISTING-SCREENER-SPINNER-MESSAGE");
  uiSignal_CreateNewScreener = this.getLocalizationKeyValue(".CREATE-NEW-SCREENER-SPINNER-MESSAGE");
  uiSignal_CreateNewScreenerData = this.getLocalizationKeyValue(".CREATE-NEW-SCREENER-DATA-SPINNER-MESSAGE");
  AppInsightsEventAction = AppInsightsEventAction;
  AppInsightsEventCategory = AppInsightsEventCategory;
  // Shared stream to communicate changes to financials component select options
  financialsUpdated$ = new BehaviorSubject<{name: string, value: string | number}[]>([]);
  // Comparison companies saved as part of the existing run
  comparisonCompanies$ = new BehaviorSubject<ComparisonCompany[]>(null);
  // If changes, then use this to check screener settings changed status
  comparisonCompaniesChanged$ = new BehaviorSubject<boolean>(null);

  private save$ = new Subject<ESGScoring>();
  private jobSuccessStatus = "Success";
  private jobFailedStatus = "Failed";
  private jobStatusPollInternval = 3000;

  // User has requested the save action on customisation screen
  save(data: ESGScoring) {
    this.save$.next(data);
  }

  // As a result of the save action, 1 of 2 things may happen
  // If existing settings match is found then show "settings exist" modal
  // If no match found, show "create new" modal
  // Depending on which stream we end up in, we will eventually switch out to the results of one or other modal response and return
  screenerCreated$ = this.save$.pipe(
    withLatestFrom(
      this.runService.activeRun,
      this.clientService.currentClientChanged
    ),
    exhaustMap(([clonedData, activeRun, client]) => {
      // Check if the maximum allowed number of screeners has been exceeded
      return this.screenerProxy
        .screenerCount(client.clientId)
        .uiSignal("")
        .pipe(
          map((result) => result.data),
          switchMap((screenerCount) => {
            if (screenerCount.count >= screenerCount.maxAllowed) {
              return from(
                this.modalService.alert(
                  this.translateService.instant(
                    this.customisationLocalisationKey +
                      ".MAX-SCREENERS-EXCEEDED-MESSAGE"
                  )
                )
              ).pipe(map((_) => false));
            } else {
              // Search for an existing client/screener combination
              return this.searchForExistingScreenerSettings(
                activeRun.info.clientId,
                clonedData
              )
                .uiSignal({
                  debugInfo: this.uiSignal_SearchingForExistingScreener,
                  uiLabel: this.uiSignal_SearchingForExistingScreener,
                })
                .pipe(
                  // Existing screener settings found?
                  switchMap((existingScreener: ApiResult<ExistingScreener>) => {
                    if (existingScreener.data) {
                      // Screener settings were found...
                      // Set up "existing screener found" modal
                      return this.setUpExistingScreenerFoundModal(
                        existingScreener,
                        activeRun.info.clientId
                      );
                    } else if (!existingScreener.data) {
                      // No screener settings were found...
                      // Set up "new screener" modal
                      return this.setUpCreateNewScreenerModal(
                        activeRun.runId,
                        activeRun.info.clientId,
                        clonedData
                      );
                    }
                  })
                );
            }
          })
        );
    })
  );

  private getLocalizationKeyValue(key: string): string {
    return this.translateService.instant(
      this.customisationLocalisationKey + key
    );
  }

  // Api call to search for an existing client/screener combination
  private searchForExistingScreenerSettings(
    clientId: number,
    data: ESGScoring
  ) {
    var screenerSettings = this.createScreenerSettings(
      data.benchmarkingSelections[0],
      data.metricTypes,
      clientId,
      data.isUsingIndustryDefaultData,
      data.comparisonCompanies
    );
    return this.screenerProxy.searchScreenerSettings(screenerSettings);
  }

  // Existing screener modal
  private setUpExistingScreenerFoundModal(
    existinScreener: ApiResult<ExistingScreener>,
    clientId: number
  ): Observable<number> {
    const modalData = this.createExistingScreenerModalData(
      existinScreener.data
    );
    return from(
      this.modalService.confirmWithComponent(
        ScreenerSettingsExistModalComponent,
        {
          data: modalData,
        }
      )
    ).pipe(
      switchMap((modalResponse: IModalConfirmResult) => {
        if (modalResponse.result) {
          // Has user decided to set this existing screener as active?
          if (modalData.setScreenerAsActiveForUser) {
            // Yes, set screener as active
            return this.updateActiveScreener(modalData.runId, clientId);
          } else {
            // No, user does not want to load screener, return home
            return of(modalData.runId);
          }
        } else {
          // user clicked cancel
          return EMPTY;
        }
      })
    );
  }

  // New screener modal
  private setUpCreateNewScreenerModal(
    runId: number,
    clientId: number,
    data: ESGScoring
  ) {
    const newScreenerModalData = this.createNewScreenerModalData(data);
    return from(
      this.modalService.confirmWithComponent(EditScreenerModalComponent, {
        data: newScreenerModalData,
      })
    ).pipe(
      // Check screener modal response
      switchMap((modalResponse: IModalConfirmResult) => {
        // New screener requested?
        if (modalResponse.result) {
          // Yes, new screener required, kick of data creation
          return this.createScreener(data, clientId, modalResponse).pipe(
            // Take the screener Id and request a dequeuer job via orchestration function
            switchMap((createScreenerResponse) => {
              const screenerId = createScreenerResponse.data;
              return this.requestNewScreenerCreateDataJob(clientId, screenerId)
                .pipe(
                  switchMap((jobKeyResponse) => {
                    // If we got a job key back then start polling the job check status
                    // We are expecting the RContainer/Orchestration function to output an MDS file and set job to "Success" or "Failure"
                    return this.pollScreenerDataReady(
                      jobKeyResponse.data,
                      runId,
                      clientId,
                      screenerId
                    );
                  })
                )
                .uiSignal({
                  debugInfo: this.uiSignal_CreateNewScreenerData,
                  uiLabel: this.uiSignal_CreateNewScreenerData,
                });
            })
          );
        } else {
          // user clicked cancel
          return EMPTY;
        }
      })
    );
  }

  // Call Api to orchestrate creation of new Screener Data
  requestNewScreenerCreateDataJob(
    clientId: number,
    screenerId: number
  ) {
    return this.screenerProxy.requestNewScreenerCreateDataJob(
      clientId,
      screenerId
    );
  }

  // Polls the webapi and checks whether the mds data file is ready
  pollScreenerDataReady(
    jobKey: number,
    runId: number,
    clientId: number,
    screenerId: number
  ) {
    return this.screenerProxy.screenerDataGenerationStatus(jobKey).pipe(
      repeat({ delay: this.jobStatusPollInternval }),
      filter(
        (response) =>
          response.data === this.jobSuccessStatus ||
          response.data === this.jobFailedStatus
      ),
      take(1),
      switchMap((jobstatus) => {
        // Was the job successful?
        if (jobstatus.data === this.jobSuccessStatus) {
          // Yes, kick off run creation
          return this.createRun(clientId, screenerId);
        } else if (jobstatus.data === this.jobFailedStatus) {
          // No, return to original run
          return of(runId);
        }
      })
    );
  }

  private updateActiveScreener(
    runId: number,
    clientId: number
  ): Observable<number> {
    return this.screenerProxy
      .updateActiveScreener(runId, clientId)
      .uiSignal({
        debugInfo: this.uiSignal_SettingActiveScreener,
        uiLabel: this.uiSignal_SettingActiveScreener,
      })
      .pipe(
        map((_) => {
          return runId;
        })
      );
  }

  // Call the run proxy and request a new run
  private createRun(clientId, screenerId): Observable<number> {
    const createRunModel = this.createRunModel(clientId, screenerId);
    
    return this.runProxy.createRun(createRunModel).pipe(
      tap((runCreatedResponse)=>{
        this.esgAppInsightsService
          .logInsight(runCreatedResponse.data.id, AppInsightsEventCategory.CLIENT_RUNS, AppInsightsEventAction.RUNCREATED);
      }),
      map((runCreatedResponse) => {
        return parseInt(runCreatedResponse.data.id);
      })
    );
  }

  // Create screener
  private createScreener(
    data: ESGScoring,
    clientId: number,
    modalResponse: IModalConfirmResult
  ) {
    const screenerSettings = this.createScreenerSettings(
      data.benchmarkingSelections[0],
      data.metricTypes,
      clientId,
      data.isUsingIndustryDefaultData,
      data.comparisonCompanies,
      modalResponse.data.screenerName,
      modalResponse.data.screenerDescription,
      modalResponse.data.setScreenerAsActiveForUser
    );

    // create the new screener, start the data processing and generate a run
    return this.screenerProxy.createScreener(screenerSettings).uiSignal({
      debugInfo: this.uiSignal_CreateNewScreener,
      uiLabel: this.uiSignal_CreateNewScreener,
    });
  }

  // Modal properties for create new screener
  private createNewScreenerModalData(data: ESGScoring) {
    
    return {
      heading: this.getLocalizationKeyValue(
        ".CREATE_NEW_SCREENER_MODAL.HEADER"
      ),
      cancelButtonText: this.getLocalizationKeyValue(
        ".CREATE_NEW_SCREENER_MODAL.MODAL_CANCEL_BUTTON"
      ),
      acceptButtonText: this.getLocalizationKeyValue(
        ".CREATE_NEW_SCREENER_MODAL.MODAL_ACCEPT_BUTTON"
      ),
      showSetAsActiveOption: true,
      showIndustrydataMessage: this.getIndustryFlagForCreatescreener(data),
    } as EditScreenerModalData;
  }

  // When creating a new run we need to generate a basic createRunModel object
  private createRunModel(clientId: number, screenerId: number): CreateRunModel {
    return {
      clientId: clientId,
      name: "Auto created run for ESG",
      description: "Runs manager was bypassed. Created run automatically",
      isNotificationRequired: false,
      isCurrentUserSubscribed: false,
      options: {
        screenerId: screenerId,
      },
    } as CreateRunModel;
  }

  // Create a screener settings object
  private createScreenerSettings(
    benchmarkingSelection: BenchmarkingSelection,
    metricTypes: MetricType[],
    clientId: number,
    isUsingIndustryDefaultData: boolean,
    comparisonCompanies?: ComparisonCompany[],
    screenerName?: string,
    screenerDescription?: string,
    isActiveScreener?: boolean
  ): ScreenerSettings {
    return {
      benchmarkingSelection,
      metricTypes,
      clientId,
      isUsingIndustryDefaultData,
      comparisonCompanies,
      screenerName,
      screenerDescription,
      isActiveScreener
    } as ScreenerSettings;
  }

  // Modal properties for exsisting screener settings found
  private createExistingScreenerModalData(
    existingScreener: ExistingScreener
  ): ScreenerSettingsExistModalData {
    return {
      heading: this.getLocalizationKeyValue(".SCREENER_EXISTS_MODAL.HEADER"),
      paragraph: this.getLocalizationKeyValue(
        ".SCREENER_EXISTS_MODAL.PARAGRAPH"
      ),
      cancelButtonText: this.getLocalizationKeyValue(
        ".SCREENER_EXISTS_MODAL.MODAL_CANCEL_BUTTON"
      ),
      acceptButtonText: this.getLocalizationKeyValue(
        ".SCREENER_EXISTS_MODAL.MODAL_ACCEPT_BUTTON"
      ),
      screenerName: existingScreener.screenerName,
      runId: existingScreener.runId,
    } as ScreenerSettingsExistModalData;
  }

  private getIndustryFlagForCreatescreener(data: ESGScoring) : boolean
  {
    var showIndustrydataflag = false;
    data.metricTypes?.forEach((metrictype) => {
      var isSelected = metrictype.items.find((metric)=>metric.key).selected;
      var isIndustryDefault = metrictype.items.find((metric)=>metric.key).isIndustryDefault;
      if(isIndustryDefault && isSelected)
      {
        showIndustrydataflag = true;
        return;
      }
    })
    return showIndustrydataflag;
  }
}
