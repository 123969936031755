<ul fxLayout="column wrap" [ngClass.gt-sm]="'fixed-height'" fxFlex>
  <li
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
    *ngFor="let source of metricSources"
  >
    <div class="logo" fxFlex="100px">
      <img [src]="'/assets/logos/' + getLogoName(source) + '.png'" />
    </div>
    <div *ngIf="metricItemCounts(source) as counts" class="scores">
      {{ counts.selectedCount }}/{{ counts.count }}
    </div>
  </li>
</ul>
