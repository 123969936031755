<div fxLayout="row" fxFlex fxLayoutGap="10px" fxLayout.lt-md="column">
    <div fxLayout="column" fxFlex>
        <esg-card 
            [heading]="companyComparisonTranslationKey + '.HEADING' | translate : {CompanyCount: comparisonCompanies?.length  ?? 0}"
        >
            <div fxLayout="row" fxLayoutGap="5px" fxLayout.lt-md="column">
                <esg-comparisoncompany-card *ngFor="let company of comparisonCompanies" fxFlex="20"
                [company]="company"
                [pillars]="pillars"
                >
                </esg-comparisoncompany-card>

                <!--Show no companies link-->
                <esg-comparisoncompany-card *ngIf="comparisonCompanies?.length<=0" fxFlex="100" 
                    [companyCount]="0"></esg-comparisoncompany-card>
                <!--Show some companies link-->
                <esg-comparisoncompany-card
                    *ngIf="comparisonCompanies?.length>0 && comparisonCompanies?.length<5" 
                    fxFlex="20" 
                    [companyCount]="comparisonCompanies.length"></esg-comparisoncompany-card>
            </div>
        </esg-card>
    </div>
</div>