import { Component, Input } from "@angular/core";

@Component({
  selector: "esg-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent {
  @Input() heading: string;
  @Input() paragraph: string;
  @Input() linkLocation: string;
  @Input() linkText: string;
}
