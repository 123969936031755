import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ConfigService } from "@wtw/platform/services";
import { map } from "rxjs";

@Component({
  selector: "esg-coming-soon",
  templateUrl: "./coming-soon.component.html",
  styleUrls: ["./coming-soon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComingSoonComponent {
  constructor(private _config: ConfigService) {}

  email$ = this._config.settings.pipe(map((s) => s.contactEmail));
}
