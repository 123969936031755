import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MetricType } from "src/app/api/generated";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "esg-risk-metrics",
  templateUrl: "./risk-metrics.component.html",
  styleUrls: ["./risk-metrics.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMetricsComponent { 
constructor(
  private translateService: TranslateService
){}
  @Input() metricSources: string[];
  @Input() metricTypes: MetricType[];

  metricItemCounts(metricSource: string) {
    const metricItemsForSource = this.metricTypes
      .filter((mt) => mt.source.startsWith(metricSource))
      .flatMap((mt) => mt.items);

    return {
      count: metricItemsForSource.length,
      selectedCount: metricItemsForSource.filter((i) => i.selected).length,
    };
  }

  getLogoName(source: string)
  {
    const wtwLogoName = this.translateService.instant("CURRENT_MODEL.GLOBAL.RISK_METRICS.WTW.NAME");
    const wtwinternalLogoName = this.translateService.instant("CURRENT_MODEL.GLOBAL.RISK_METRICS.WTWINTERNAL.NAME");

    return (source == wtwLogoName) ? source = wtwinternalLogoName : source;
  }
}

