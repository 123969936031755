<div>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ data.heading }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ data.paragraph }}</p>

    <ul fxLayout="column" fxLayout.gt-sm="row wrap">
      <li
        fxLayout="row"
        fxLayoutGap="20px"
        [ngClass]="{'document-card': true, 'disabled': download.isDisabled}"
        (click)="selectReportClick(download)"
        [class.selected]="download === selectedDownload"
        fxFlex="50%"
        *ngFor="let download of data.downloads"
      >
        <div>
          <img 
            *ngIf="download.IS_DETAILED_REPORT"
            src="/assets/images/download-report-advanced.svg"
          />
          <img
            *ngIf="!download.IS_DETAILED_REPORT"
            src="/assets/images/download-report-simple.svg"
          />
        </div>
        <div fxLayout="column">
          <div class="heading">{{ download.NAME }}</div>
          <div class="description">{{ download.DESCRIPTION }}</div>
        </div>
      </li>
    </ul>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary download-button"
      [class.disabled]="!selectedDownload"
      (click)="download()"
    >
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div>{{ data.downloadButtonText }}</div>
        <img src="/assets/images/download-pdf-white.svg" />
      </div></button
    ><button type="button" class="btn btn-default" (click)="close()">
      {{ data.closeButtonText }}
    </button>
  </div>
</div>
