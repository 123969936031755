import { AppInsightsEventCategory } from './../../shared/enums/app-insights-event-category';
import { EsgAppInsightsService } from 'src/app/services/esg-app-insights-service';
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { IModalConfirmResult, IStandardModalConfig } from "@wtw/platform/interfaces";
import {
  ClientService,
  ModalService,
  RunService,
  UserService,
} from "@wtw/platform/services";
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  exhaustMap,
  filter,
  from,
  map,
  of,
  startWith,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs";
import { ScreenerListItem } from "src/app/api/generated";
import { ScreenerSettings } from "src/app/api/generated/ScreenerSettings";
import { ScreenersProxy } from "src/app/api/ScreenersController";
import { EditScreenerModalData } from "src/app/shared/components/edit-screener-modal/edit-screener-modal-data";
import { EditScreenerModalComponent } from "src/app/shared/components/edit-screener-modal/edit-screener-modal.component";
import { AppInsightsEventAction } from 'src/app/shared/enums/app-insights-event-action';

@Component({
  selector: "esg-screener-management",
  templateUrl: "./screener-management.component.html",
  styleUrls: ["./screener-management.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenerManagementComponent {
  constructor(
    private clientService: ClientService,
    private screenersProxy: ScreenersProxy,
    private router: Router,
    private runService: RunService,
    private userService: UserService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private _esgAppInsightsService: EsgAppInsightsService,
  ) {}

  screenerManagementKey = "CURRENT_MODEL.PAGES.SCREENER_MANAGEMENT";
  screenerManagementModalKey = "CURRENT_MODEL.PAGES.SCREENER_MANAGEMENT.EDIT_SCREENER_MODAL";
  isClient = this.userService.currentUserInfo.isClient;
  isAdmin = this.userService.currentUserInfo.isAdmin;
  
  selectedScreener = null;

  screenerFilterAction$ = new BehaviorSubject<"ALL" | "MINE">("ALL");
  colleagueSelectedAction$ = new BehaviorSubject<void>(null);

  client$ = this.clientService.currentClientChanged;

  private deleteAction$ = new Subject<ScreenerListItem>();
  private editAction$ = new Subject<ScreenerListItem>();

  screenerEditedComplete$ = this.editAction$.pipe(
    exhaustMap((screener) => {
      const newScreenerModalData = {
        screenerId: screener.screenerId,
        screenerName: screener.screenerName,
        screenerDescription: screener.screenerDescription,
        heading: this.translateService.instant(
          this.screenerManagementModalKey + ".HEADER"
        ),
        acceptButtonText: this.translateService.instant(
          this.screenerManagementModalKey + ".MODAL_ACCEPT_BUTTON"
        ),
        cancelButtonText: this.translateService.instant(
          this.screenerManagementModalKey + ".MODAL_CANCEL_BUTTON"
        ),
      } as EditScreenerModalData;
      return from(
        this.modalService.confirmWithComponent(EditScreenerModalComponent, {
          data: newScreenerModalData,
        })
      ).pipe(
        // Check modal response
        switchMap((modalResponse: IModalConfirmResult) => {
          // Update requested
          if (modalResponse.result) {
            const screenerSettings = {
              screenerName: modalResponse.data.screenerName,
              screenerDescription: modalResponse.data.screenerDescription,
            } as ScreenerSettings;

            return this.screenersProxy
              .edit(screenerSettings, screener.screenerId)
              .uiSignal({
                uiLabel: this.translateService.instant(
                  this.screenerManagementKey + ".MESSAGES.EDITING"
                ),
                debugInfo: "",
              })
              .pipe(
                map(() => {
                  this._esgAppInsightsService.logInsight(screener.runId, AppInsightsEventCategory.CLIENT_RUNS, AppInsightsEventAction.RUNEDITED);
                  return of(true);
                })
              );
          }
          return EMPTY;
        })
      );
    })
  );

  screenerDeletedComplete$ = this.deleteAction$.pipe(
    exhaustMap((screener) => {
      if (screener.hasActiveUsers) {
        return from(
          this.modalService.alert(
            this.translateService.instant(
              this.screenerManagementKey + ".MESSAGES.SCREENER_CURRENTLY_ACTIVE"
            )
          )
        ).pipe(map((_) => false));
      } else {
        return from(
          this.modalService.confirm({
            title: this.translateService.instant(
              this.screenerManagementKey + ".MESSAGES.SURE_DELETE"
            ),
          } as IStandardModalConfig)
        ).pipe(
          switchMap((deleteConfirmResult) => {
            if (deleteConfirmResult) {
              // log app insights
              this._esgAppInsightsService.logInsight(screener.runId, AppInsightsEventCategory.CLIENT_RUNS, AppInsightsEventAction.RUNDELETED);
              // User really wants to delete screener
              return this.screenersProxy
                .delete(screener.screenerId)
                .uiSignal({
                  uiLabel: this.translateService.instant(
                    this.screenerManagementKey + ".MESSAGES.DELETING"
                  ),
                  debugInfo: "",
                })
                .pipe(
                  withLatestFrom(
                    this.runService.activeRun,
                    this.clientService.currentClientChanged
                  ),
                  switchMap(([_, run, client]) => {
                    if (screener.runId === run.runId) {
                      // They deleted the currently loaded screener - so lets redirect to the default one
                      return this.screenersProxy
                        .getDefaultScreenerRunId(client.clientId)
                        .uiSignal(" ")
                        .pipe(
                          map((c) => c.data),
                          tap((runId) => {
                            this.router.navigateByUrl(
                              "/run/" + runId + "/screener-management"
                            );
                          }),
                          map((_) => true)
                        );
                    } else {
                      return of(true);
                    }
                  })
                );
            } else {
              return of(false);
            }
          })
        );
      }
    }),

    filter((result) => result)
  );

  viewModel$ = combineLatest([
    combineLatest([
      this.client$,
      this.screenerDeletedComplete$.pipe(startWith(false)),
      this.screenerEditedComplete$.pipe(startWith(false)),
    ]).pipe(
      switchMap(([client, _, __]) => {
        return this.screenersProxy.screeners(client.clientId);
      })
    ),
    this.screenerFilterAction$,
    this.runService.activeRun,
  ]).pipe(
    map(([screeners, filter, activeRun]) => {
      return {
        screeners: screeners.data.filter(
          (item) => filter == "ALL" || (filter == "MINE" && item.createdByMe)
        ),
        currentRunId: activeRun.runId,
      };
    })
  );

  screenerFilterChanged(event: MatRadioChange) {
    this.screenerFilterAction$.next(event.value);
  }

  // Loads screener
  load(screener: ScreenerListItem) {
    this._esgAppInsightsService.logInsight(screener.runId, AppInsightsEventCategory.CLIENT_RUNS, AppInsightsEventAction.RUNLOADED);
    this.router.navigateByUrl("/run/" + screener.runId + "/executive-summary");
  }

  deleteScreener(screener: ScreenerListItem) {
    this.deleteAction$.next(screener);
  }

  editScreener(screener: ScreenerListItem) {
    this.editAction$.next(screener);
  }
}
