import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RunService } from "@wtw/platform/services";
import { combineLatest, map } from "rxjs";
import { TabVM } from "src/app/shared/components/tabs/tabs.component";

@Component({
  selector: "esg-executive-summary-detail",
  templateUrl: "./executive-summary-detail.component.html",
  styleUrls: ["./executive-summary-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExecutiveSummaryDetailComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private runService: RunService,
    private translateService: TranslateService
  ) {}

  viewModel$ = combineLatest([
    this.runService.activeRun,
    this.activatedRoute.url,
  ]).pipe(
    map(([activeRun, url]) => {
      return {
        pillar: activeRun.data.esgPillars.find(
          (s) => s.key.toLowerCase() == url[0].path.toLocaleLowerCase()
        ),
        tabs: this.activatedRoute.routeConfig.children //Create the tabs from the page's children (as specified in routes.ts)
          .filter((child) => child.path !== "")
          .filter((child) => !child.path.startsWith("~"))
          .filter((child) => !(child.path === "companies-comparison" && !activeRun.data.comparisonCompanies))
          .map((child) => {
            return {
              link: child.path,
              text: this.translateService.instant(
                "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY_DETAIL.THEMES." + child.path.toUpperCase() + ".NAME"
              ),
            } as TabVM;
          })
      };
    })
  );
}
