<div
  class="outer"
  [style.background-color]= "isSelectedAvailable ? state ? colorOn : colorOff : colorOff"
  [style.padding.px]="borderPixels"
>
  <div
    (click)="clicked.emit()"
    class="switch"
    [style.height.px]="knobWidthPixels"
    [style.width.px]="knobWidthPixels * 3"
    fxLayout="row"
    [fxLayoutAlign]="isSelectedAvailable ? state ? 'start center' : 'end center': 'end center'"
  >
    <div
      [style.color]="textColor"
      class="text"
      [fxFlex]="knobWidthPixels * 2 + 'px'"
    >
      {{ isSelectedAvailable ? state ? textOn : textOff : textNA}}
    </div>

    <div
      [style.left.px]="isSelectedAvailable ? state ? knobWidthPixels * 2 : 0 : 0"
      class="knob"
      [style.height.px]="knobWidthPixels"
      [style.width.px]="knobWidthPixels"
      [style.background-color]="knobColor"
    ></div>
  </div>
</div>
