import { ChangeDetectionStrategy } from '@angular/core';
import { ESGPillar } from '../../../../api/generated/ESGPillar';
import { ComparisonCompany } from 'src/app/api/generated/ComparisonCompany';
import { Component, Input  } from '@angular/core';

@Component({
  selector: 'esg-comparisoncompany-card',
  templateUrl: './comparisoncompany-card.component.html',
  styleUrls: ['./comparisoncompany-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonCompanyCardComponent {
  constructor() { }

  @Input() company: ComparisonCompany;
  @Input() pillars: ESGPillar[];
  @Input() companyCount: number;

  companyComparisonTranslationKey = "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY.COMPONENTS.COMPANY_COMPARISON";

  pillarScore(key: string)
  {
    const comparisonCompanyPercentile = this.company.esgPillars.find(x => x.key == key).percentile;
    return comparisonCompanyPercentile;
  }

  pillarArrow(key: string) {
    const comparisonCompanyPercentile = this.company.esgPillars.find(x => x.key == key).percentile;
    const parentPercentile = this.pillars.find(x => x.key == key).percentile;
     if(comparisonCompanyPercentile > parentPercentile)
     {
       return "icon-back-arrow-2 higher"
     }
     else if(comparisonCompanyPercentile < parentPercentile) {
       return "icon-back-arrow-2 lower"
     }
     return "icon-back-arrow-2 equal"
  }
}
