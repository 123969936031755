import { AutocompleteClientSearchComponent } from '@wtw/toolkit/src/directives/autocomplete_clientSearch.directive';
import { ComparisonCompany } from './../../../api/generated/ComparisonCompany';
import { ScreenersService } from 'src/app/services/screeners-service';
import { IStandardModalConfig } from '@wtw/platform/interfaces';
import { ScreenersProxy } from 'src/app/api/ScreenersController';
import { map, of, switchMap, tap} from 'rxjs';
import { ReferenceDataProxy } from 'src/app/api/ReferenceDataController';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, Renderer2, ViewChild } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { ModalService } from '@wtw/platform/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "esg-individual-company-details",
    templateUrl: "./individual-company-details.component.html",
    styleUrls: ["./individual-company-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class IndividualCompanyDetailsComponent implements AfterViewInit, OnDestroy {
    constructor(
      private referenceDataProxy: ReferenceDataProxy,
      private screenersProxy: ScreenersProxy,
      private translateService: TranslateService,
      private modalService: ModalService,
      private screenersService: ScreenersService,
      private renderer: Renderer2,
    ) {}

    @ViewChild(AutocompleteClientSearchComponent) private _searchComponent!: AutocompleteClientSearchComponent;
    private unListeneToAutoCompleteFocus: () => void;

    ngAfterViewInit(): void {
      this.unListeneToAutoCompleteFocus = this.renderer.listen(this._searchComponent.inputRef.nativeElement, 'focus', () => {
        this.onFocus();
      });
    }

    individualCompanyDetailsTranslationKey = "CURRENT_MODEL.PAGES.CUSTOMIZE.INDIVIDUAL_COMPANY_DETAILS_SECTION";
    displayResults: boolean = false;
    companyRemoved$ = new BehaviorSubject(null);
    maxCompanyCount = +this.translateService.instant(this.individualCompanyDetailsTranslationKey + ".MAX_COMPANY_COUNT");
    comparisonCompanySelections: ComparisonCompany[] = [];
    comparisonCompanySelectionOperation: 'add' | 'remove' | '' = '';

    search: any = (clientName: string) => {
      const results = this.referenceDataProxy.clientSearch(clientName);
      return results;
    }

    $comparisonCompanies = this.screenersService.comparisonCompanies$
      .pipe(
        tap((comparisonCompanies) => {
          this.comparisonCompanySelections = comparisonCompanies
        }),
        switchMap((comparisonCompanies) => {
          // If adding, we need to check for default data status
          if(this.comparisonCompanySelectionOperation === 'add') {
            const clientId = comparisonCompanies[comparisonCompanies.length -1].clientId;
            return this.screenersProxy.isUsingIndustryDefaultData(clientId).uiSignal({
              debugInfo: this.translateService.instant(this.individualCompanyDetailsTranslationKey + ".CHECKING_DATA"),
              uiLabel: this.translateService.instant(this.individualCompanyDetailsTranslationKey + ".CHECKING_DATA"),
            }).pipe(
              tap((isUsingIndustryDefaultData) => {
                comparisonCompanies.find((company) => company.clientId === clientId).isUsingIndustryDefaultData = isUsingIndustryDefaultData.data;
              })
              ,map(()=>{
                return [comparisonCompanies];
              })
            )
          }
          else {
            return of([comparisonCompanies]);
          }
        })
      ).pipe(
        tap(()=>{
          if(this.comparisonCompanySelectionOperation !== '') {
            this.screenersService.comparisonCompaniesChanged$.next(true);
          }
        }),
        map(([comparisonCompanies]) => {
          return {
            companies: comparisonCompanies
          }
        })
    )

    companySelected(comparisonCompanySelection: ComparisonCompany) {
      const clientId = comparisonCompanySelection.clientId;
      const clientName = comparisonCompanySelection.name;
      if(this.comparisonCompanySelections.find((company) => company.clientId === comparisonCompanySelection.clientId)) {
        this.modalService.alert("Company already added");
      }
      else {
        this.addPeerCompany(clientId, clientName, false);
    }
    }

    addPeerCompany(clientId: number, name: string, isUsingIndustryDefaultData: boolean) {
      this.comparisonCompanySelectionOperation = 'add';
      const peerCompany = {clientId: clientId, name: name, isUsingIndustryDefaultData: isUsingIndustryDefaultData} as ComparisonCompany;
      this.comparisonCompanySelections.push(peerCompany); 
      this.screenersService.comparisonCompanies$.next(this.comparisonCompanySelections);
    }

    removePeerCompany(index: number) {
      this.comparisonCompanySelectionOperation = 'remove';
      this.comparisonCompanySelections.splice(index, 1);
      this.screenersService.comparisonCompanies$.next(this.comparisonCompanySelections);
    }

    statuschanged(status: boolean) {
      this.displayResults = status;
    }

    onFocus() {
      if(this.comparisonCompanySelections.length >= this.maxCompanyCount)
      {
        const translationKeyBase = this.individualCompanyDetailsTranslationKey + ".MAX_MODAL";
        var modalConfig = { 
          title: this.translateService.instant(translationKeyBase + ".TITLE"), 
          withHeader:true, 
          noButton:{display: false},
          yesButton:{display: true, text: this.translateService.instant(translationKeyBase + ".OK_BUTTON")},
          message: this.translateService.instant(translationKeyBase + ".MESSAGE")
        } as IStandardModalConfig;

        this.modalService.confirm(modalConfig);
    }
  }

    ngOnDestroy() {
      this.unListeneToAutoCompleteFocus();
    }    
  }