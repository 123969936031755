import { ESGPillar } from './../../../api/generated/ESGPillar';
import { ComparisonCompany } from 'src/app/api/generated/ComparisonCompany';
import { ChangeDetectionStrategy, Component, Input  } from '@angular/core';

@Component({
  selector: 'esg-comparison-companies',
  templateUrl: './comparison-companies.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonCompaniesComponent {

  constructor() { }

  @Input() comparisonCompanies: ComparisonCompany[];
  @Input() pillars: ESGPillar[];

  companyComparisonTranslationKey = "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY.COMPONENTS.COMPANY_COMPARISON";
}
