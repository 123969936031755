import { CircleChartPercentagesComponent } from './circle-chart-percentages/circle-chart-percentages.component';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppHeaderComponent } from "./app-header/app-header.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RouterModule } from "@angular/router";
import { ScoreCardComponent } from "./score-card/score-card.component";
import { ScoreSliderComponent } from "./score-slider/score-slider.component";
import { CircleChartComponent } from "./circle-chart/circle-chart.component";
import { CardComponent } from "./card/card.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { TabsComponent } from "./tabs/tabs.component";
import { NextPreviousComponent } from "./next-previous/next-previous.component";
import { TranslateModule } from "@ngx-translate/core";
import { DownloadModalComponent } from "./download-modal/download-modal.component";
import { ButtonMenuComponent } from "./button-menu/button-menu.component";
import { SwitchComponent } from "./switch/switch.component";
import { ButtonTabsComponent } from "./button-tabs/button-tabs.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { GraphBarComponent } from './graph-bar/graph-bar.component';
import { ScreenerSettingsExistModalComponent } from "./screener-settings-exist-modal/screener-settings-exist-modal.component";
import { EditScreenerModalComponent } from './edit-screener-modal/edit-screener-modal.component';
import { SimpleSpinnerComponent } from './simple-spinner/simple-spinner.component';
import { RangeChartComponent } from './range-chart.component.ts/range-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';

@NgModule({
  declarations: [
    AppHeaderComponent,
    ScoreCardComponent,
    ScoreSliderComponent,
    CircleChartComponent,
    CircleChartPercentagesComponent,
    RangeChartComponent,
    CardComponent,
    TabsComponent,
    NextPreviousComponent,
    DownloadModalComponent,
    ButtonTabsComponent,
    ButtonMenuComponent,
    SwitchComponent,
    ComingSoonComponent,
    GraphBarComponent,
    ScreenerSettingsExistModalComponent,
    EditScreenerModalComponent,
    SimpleSpinnerComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    FormsModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    ReactiveFormsModule,
    HighchartsChartModule
  ],
  exports: [
    AppHeaderComponent,
    ScoreCardComponent,
    ScoreSliderComponent,
    CircleChartComponent,
    CircleChartPercentagesComponent,
    RangeChartComponent,
    CardComponent,
    TabsComponent,
    NextPreviousComponent,
    DownloadModalComponent,
    ButtonTabsComponent,
    ButtonMenuComponent,
    SwitchComponent,
    ComingSoonComponent,
    GraphBarComponent,
    ScreenerSettingsExistModalComponent,
    SimpleSpinnerComponent
  ],
})
export class SharedComponentsModule {}
