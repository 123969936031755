<div
  fxLayout="row"
  [style.backgroundColor]="backgroundColor"
  [style.height.px]="heightPixels"
>
  <div
    class="label"
    [fxFlex]="labelWidthPixels + 'px'"
    fxLayout="row"
    fxLayoutAlign="end center"
  >
    {{ label }}
  </div>
  <div fxFlex fxLayout="row">
    <div
      #bar
      [fxFlex]="percentageValue + '%'"
      [style.backgroundColor]="barColor"
      fxLayout="row"
      fxLayoutAlign="end center"
    >
      <div
        *ngIf="!showOffsetLabel"
        class="percent-label"
        [style.color]="percentLabelColor"
      >
        {{ percentageLabel }}
      </div>
    </div>
    <div
      *ngIf="showOffsetLabel"
      class="offset-percent-label"
      fxLayout="row"
      fxLayoutAlign="center center"
      [style.color]="offsetPercentLabelColor"
    >
      {{ percentageLabel }}
    </div>
  </div>
</div>
