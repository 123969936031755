<div class="canvas-container" fxLayout="column" fxLayoutAlign="center center">
  <canvas [style.height.px]="options?.height ?? defaultHeight" #chart></canvas>
</div>
<div
  class="circle-container"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="5px"
>
  <div class="primary-text">{{ options.primaryText }}</div>
  <div class="secondary-text">{{ options.secondaryText }}</div>
</div>
