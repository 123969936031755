<div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
  <div
    fxFlex="55px"
    class="number"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    {{ number }}
  </div>
  <div class="title">
    {{ title }}
  </div>
  <div fxFlex="20px"></div>
</div>

<div class="content-container">
  <ng-content></ng-content>
</div>
