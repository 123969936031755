<esg-next-previous fxHide.gt-sm></esg-next-previous>

<div *ngIf="viewModel$ | async as vm">
  <p class="description">{{ vm.paragraph }}</p>

  <h2>{{ recommendationsTranslationKey + ".TITLE" | translate }}</h2>

  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap.lt-md="15px"
    fxLayoutAlign="space-between start"
  >
    <div
      [innerHTML]="
        recommendationsTranslationKey + '.COUNTS'
          | translate
            : {
                FilteredCount: vm.filteredRecommendations?.length || 0,
                TotalCount: vm.totalCount || 0
              }
      "
    ></div>

    <div
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayout.lt-sm="column"
      fxLayoutGap.lt-sm="5px"
      fxLayoutAlign="end"
      class="filters"
    >
      <mat-checkbox
        *ngFor="let filter of vm.filters"
        (change)="filterClicked(filter.tierId)"
        [ngModel]="filter.selected"
        >{{
          "CURRENT_MODEL.GLOBAL.TIERS." + filter.tierId | translate
        }}</mat-checkbox>
    </div>
  </div>

  <div
    class="recommendations-container"
    fxLayout="row wrap"
    fxLayout.lt-sm="column"
    fxLayoutGap="20px"
    ngStyle.gt-xs="margin-right: -20px"
  >
    <esg-recommendation-card
      fxFlex="0 1 calc(33.3% - 20px)"
      fxFlex.lt-md="0 1 calc(50% - 20px)"
      fxFlex.lt-sm="100%"
      *ngFor="let recommendation of vm.filteredRecommendations"
      (actionLeverSelected)="navigateToActionLever(vm.pillarKey, $event, vm.themeKey)"
      [recommendation]="recommendation"
      [companyScoreTitle]="
        recommendationsTranslationKey + '.COMPANY_SCORE.TITLE' | translate
      "
      [companyPrimaryColor]="
        recommendationsTranslationKey + '.COMPANY_SCORE.PRIMARY_COLOR'
          | translate
      "
      [companySecondaryColor]="
        recommendationsTranslationKey + '.COMPANY_SCORE.SECONDARY_COLOR'
          | translate
      "
      [industryScoreTitle]="
        recommendationsTranslationKey + '.INDUSTRY_SCORE.TITLE' | translate
      "
      [industryPrimaryColor]="
        recommendationsTranslationKey + '.INDUSTRY_SCORE.PRIMARY_COLOR'
          | translate
      "
      [industrySecondaryColor]="
        recommendationsTranslationKey + '.INDUSTRY_SCORE.SECONDARY_COLOR'
          | translate
      "
      [circleInnerText]="
        recommendationsTranslationKey + '.CIRCLE_INNER_TEXT' | translate
      "
    ></esg-recommendation-card>
  </div>
</div>
