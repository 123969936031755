import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "esg-switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent {
  @Input() state = true;
  @Input() isSelectedAvailable = true; 
  @Input() knobWidthPixels = 20;
  @Input() knobColor = "white";
  @Input() colorOn = "#007FA7";
  @Input() colorOff = "#979797";
  @Input() textColor = "white";
  @Input() textOn = "ON";
  @Input() textOff = "OFF";
  @Input() textNA = "NA";
  @Input() borderPixels = 2;
  @Output() clicked = new EventEmitter();
}
