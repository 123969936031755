import { EMPTY, of, switchMap } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { IAnalyticsSendInput } from '@wtw/platform/interfaces';
import { ApplicationInsightsService, ClientService, RunService } from '@wtw/platform/services';
import { Subscription, take } from 'rxjs';
import { AppInsightsProxy } from '../api/AppInsightsController';
import { AppInsightsEventAction } from '../shared/enums/app-insights-event-action';
import { AppInsightsEventCategory } from '../shared/enums/app-insights-event-category';

@Injectable({
  providedIn: "root",
})
export class EsgAppInsightsService implements OnDestroy {
  constructor(
    private insightsService: ApplicationInsightsService,
    private _appInsightsProxy: AppInsightsProxy,
    private _runService: RunService,
    private _clientService: ClientService) 
  {}

  private subscriptions$ = new Subscription();

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  // Temporary persistence to reduce server calls
  // Has run/screener been marked as completed for this user
  appInsightsRunCompleted: number;

  logInsight(runId: number, eventCategory: string, eventAction, additionalFields = null) {
    const insight = {
      eventCategory: eventCategory,
      eventAction: eventAction,
      runId: runId,
      addtionalFields: additionalFields
    } as IAnalyticsSendInput;

    if(additionalFields) {
      insight.addtionalFields = additionalFields
    }

    this.insightsService.send(insight);
  }

  // Check if the run is marked as "RunCompleted"
  // If not insert a new record
  markRunAsComplete() {
    // Subscribe to the run in order to check the current client
    // This is required, otherwise by the time the Platform Insights service is called, the underlying client replaysubject may not be ready.
    this.subscriptions$.add(this._runService.activeRun
        .pipe(
          take(1),
          switchMap((run)=> {
            if(this.appInsightsRunCompleted !== run.runId) {
              return this._appInsightsProxy.checkRunCompleted(run.runId)
              .pipe(
                switchMap((runCompleted)=>{
                  // No record found in database
                  if(!runCompleted.data) {
                    if(!this._clientService.currentClient || this._clientService.currentClient.clientId !== run.info.clientId) {
                      return this._clientService.loadById(run.info.clientId).pipe(
                        switchMap(()=> {
                          return this._appInsightsProxy.markRunCompleted(run.runId).pipe(
                            switchMap(()=>{
                              this.logInsight(run.runId, AppInsightsEventCategory.CLIENT_RUNS, AppInsightsEventAction.RUNCOMPLETED);
                              return EMPTY;
                            })
                          );
                        })
                      );
                    }
                    else {
                      return this._appInsightsProxy.markRunCompleted(run.runId).pipe(
                        switchMap(()=>{
                          this.logInsight(run.runId, AppInsightsEventCategory.CLIENT_RUNS, AppInsightsEventAction.RUNCOMPLETED);
                          return EMPTY;
                        })
                      );
                    }
                  }
                  else {
                    return of(this.appInsightsRunCompleted = run.runId);
                  }
                })
              )
            }
            else {
              return EMPTY;
            }
          })
        ).subscribe());
    }
}
