import { Component, Input, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
more(Highcharts);
import Xrange from 'highcharts/modules/xrange';
import { RangeChartOptions } from "../../interfaces/range-chart-options";
Xrange(Highcharts);

@Component({
  selector: "esg-range-chart",
  templateUrl: "range-chart.component.html",
  styleUrls: ["./range-chart.component.scss"],
})
export class RangeChartComponent implements OnInit {
  @Input() rangeChartOptions: RangeChartOptions

  Highcharts = Highcharts;
  chartOptions: Highcharts.Options;

  ngOnInit(): void {
    this.chartOptions = this.getChartOptions();
  }

  private getChartOptions() : Highcharts.Options {
    return {
      chart: {
        backgroundColor: null,
        type: 'xrange'
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },        
      xAxis: {
        min: this.rangeChartOptions.minXAxis,
        max: this.rangeChartOptions.maxXAxis,
        tickInterval: 10
      },
      yAxis: {
        gridLineColor: 'transparent',
          title: {
              text: ''
          },
          labels: {
            style: {
                fontSize:'11px',
                fontWeight: 'bold',
                color:'black'
            }
        },
        categories: this.rangeChartOptions.labels,
      },
      plotOptions: {
        series: {
            borderWidth: 0,
            borderRadius:0,
            enableMouseTracking: false,
            dataLabels: {
              align: 'left',
              enabled: true
          }
        },
        column: {
          grouping: false,
          shadow: false,
          borderWidth: 0
        }
    },
      series: Array.from([this.getSeriesOptions()]), 
    } as Highcharts.Options
  }

  private getSeriesOptions(): Highcharts.SeriesOptionsType {
    let data = [];
    this.rangeChartOptions.scores.map((score, index) => {
      data.push({
        x: score[0].score,
        x2: score[1].score,
        y:index,
        avg: score[2].score,
        color: this.rangeChartOptions.seriesColors[index]
      })
    });
    

    return {
      pointPadding: .02,
      groupPadding: 0,
      borderWidth: 0,
      showInLegend: false,
      data: data,
      dataLabels: [{
        enabled: true,
        format: 'avg: {point.avg}',
        style: {
          color: 'white',
          textOutline: 'none'
        }     
      },
      {
        x: 0,
        align: 'right',
        format: '{point.x2}',
        style: {
          color: 'white',
          textOutline: 'none'
        }
      },
      {
        x: 0,
        align: 'left',
        format: '{point.x}',
        crop: false,
        overflow: 'allow',
        style: {
          color: 'white',
          textOutline: 'none'
        }
      }]
    } as Highcharts.SeriesOptionsType;
  }
}


