import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { Score } from "src/app/api/generated";
import { EsgScoringDataUtilities } from "src/app/shared/helpers/esg-scoring-data-utilities";
more(Highcharts);

export class PerformanceScoreOptions {
  xAxisTitle: string;
  yAxisTitle: string;
  industryScoreColour: string;
  clientScoreColour: string;
  percentilePolygonColour: string;
}

@Component({
  selector: "esg-performance-score",
  templateUrl: "./performance-score.component.html",
  styleUrls: ["./performance-score.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceScoreComponent {
  private options$ = new BehaviorSubject<PerformanceScoreOptions>(
    {} as PerformanceScoreOptions
  );
  private performanceScores$ = new BehaviorSubject<Score[]>([]);

  @Input() set options(options: PerformanceScoreOptions) {
    this.options$.next(options);
  }

  @Input() set performanceScores(scores: Score[]) {
    scores = EsgScoringDataUtilities.sortPillarPerformanceScoresByDate(scores, 'asc');
    this.performanceScores$.next(scores);
  }

  viewModel$ = combineLatest([this.options$, this.performanceScores$]).pipe(
    map(([options, scores]) => {
      const xAxisLabels = scores.map((score) =>
        moment(score.date).format("MM-YYYY")
      );

      const seriesData = this.buildSeriesData({
        scores,
        clientScoreColour: options.clientScoreColour,
        industryScoreColour: options.industryScoreColour,
        percentilePolygonColour: options.percentilePolygonColour

      });

      const chartOptions = this.buildChartOptions({
        seriesData,
        xAxisTitle: options.xAxisTitle,
        yAxisTitle: options.yAxisTitle,
        xAxisLabels,
      });

      return {
        chartOptions,
        highcharts: Highcharts,
      };
    })
  );

  private buildChartOptions(config: {
    seriesData: Highcharts.SeriesOptionsType[];
    xAxisTitle: string;
    yAxisTitle: string;
    xAxisLabels: string[];
  }): Highcharts.Options {
    return {
      series: config.seriesData,
      credits: {
        enabled: false,
      },
      chart: {
        plotBorderWidth: 1,
      },
      legend: {
        enabled: true,
        symbolWidth: 1,
        symbolPadding: 10,
        align: 'right',
        verticalAlign: 'top'
      },
			tooltip: {
				shared: true
			},          
      title: {
        text: null,
      },
      xAxis: {
        categories: config.xAxisLabels,
        max: config.xAxisLabels.length - 1,
        tickWidth: 1,
        title: {
          text: config.xAxisTitle,
        },
      },
      yAxis: {
        tickInterval: 10,
        title: {
          text: config.yAxisTitle,
        },
      },
    } as Highcharts.Options;
  }

  private buildSeriesData(config: {
    scores: Score[];
    clientScoreColour: string;
    industryScoreColour: string;
    percentilePolygonColour: string;
  }): Highcharts.SeriesOptionsType[] {
    const allSeries: Highcharts.SeriesOptionsType[] = [];

    allSeries.push(this.LineSeriesData(config.scores.map(s => s.clientScore), config.clientScoreColour, 'Actual'));
    allSeries.push(this.LineSeriesData(config.scores.map(s => s.industryScore), config.industryScoreColour, 'Industry'));
    allSeries.push(this.AreaSeriesData(config.scores, config.percentilePolygonColour, 'Low - High Range'));
    return allSeries
  }

  private LineSeriesData(scores: Number[], fillColor: string, seriesName: string) : Highcharts.SeriesOptionsType {
    const data = scores.map(
      (score, index) =>
        ({
          x: index,
          y: score,
        } as Highcharts.PointOptionsObject));

      return {
        name: seriesName,
        data: data,
        type: "line",
        marker: {
            fillOpacity: 1,
            radius: 15,
            symbol: 'circle'
        },
        color: fillColor,
        zIndex:1
      } as Highcharts.SeriesOptionsType;
  }

  private AreaSeriesData(scores: Score[], fillColor: string, seriesName: string) : Highcharts.SeriesOptionsType {
    const data = scores.map(
      (score) =>
        ([
          score.industry25thPerc, score.industry75thPerc
        ] as Highcharts.PointOptionsObject));

    return {
      name: seriesName,
      data: data,
      type: 'arearange',
      lineWidth: 0,
      color: fillColor,
      fillOpacity: 0.3,
      zIndex: 0,
      marker: {
        enabled: false,
        symbol: 'diamond'
      }
    } as Highcharts.SeriesOptionsType;
  }
}
