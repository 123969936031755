import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from "@angular/core";
import { EsgCircleChartData } from "../../interfaces/esg-circle-chart-data";
import { EsgCircleChartOptions } from "../../interfaces/esg-circle-chart-options";

@Component({
  selector: "esg-circle-chart-percentages",
  templateUrl: "circle-chart-percentages.component.html",
  styleUrls: ["./circle-chart-percentages.component.scss"],
})
export class CircleChartPercentagesComponent implements AfterViewInit {

  defaultHeight = 100;
  defaultLineWeight = 22;
  defaultMaxValue = 100;

  @Input() data: EsgCircleChartData[]
  @Input() options: EsgCircleChartOptions
  @ViewChild("chart") chart: ElementRef;

  ngAfterViewInit() {
    this.createChart();
  }

  createChart() {
    this.data.forEach((c, i) => {
      const canvas = this.chart.nativeElement;
      const ctx = canvas.getContext("2d");
      if (i == 0) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
      let lastend = 1.5 * Math.PI;
      let myTotal = c?.maxValue ?? this.defaultMaxValue;

      const w = canvas.width / 2;
      const h = canvas.height / 2;
      const length = (c.values[i].score / myTotal) * 2 * Math.PI;
      const radius = h - (i * (this.options?.lineWeight ?? this.defaultLineWeight)) / 2;
      ctx.fillStyle = c.colour;
      ctx.lineWidth = 0;
      ctx.beginPath();
      ctx.moveTo(w, h);
      ctx.arc(w, h, radius, lastend, lastend + length, false);
      ctx.lineTo(w, h);
      ctx.fill();
      lastend += length;

      ctx.fillStyle = c.secondaryColor;
      ctx.lineWidth = 0;
      ctx.beginPath();
      ctx.moveTo(w, h);
      ctx.arc(w, h, radius, lastend, 3.5 * Math.PI, false);
      ctx.lineTo(w, h);
      ctx.fill();

      ctx.fillStyle = "white";
      ctx.lineWidth = 0;
      ctx.beginPath();
      ctx.moveTo(w, h);
      const len = 2 * Math.PI;
      const r = h - ((i + 1) * (this.options.lineWeight ?? this.defaultLineWeight)) / 2 + 2;
      ctx.arc(w, h, r, lastend, lastend + len, false);
      ctx.fill();
    });
  }
}
