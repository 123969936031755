import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from "@angular/core";

@Component({
  selector: "esg-app-header",
  templateUrl: "./app-header.component.html",
  styleUrls: ["./app-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent {
  @Input() backlinkText: string;
  @Input() backlinkLocation: string;
  @Input() pageTitle: string;
  @Input() hideBreadcrumb: boolean;
}
