import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { EsgScoringDataUtilities } from "src/app/shared/helpers/esg-scoring-data-utilities"
import { Recommendation } from "src/app/api/generated";

@Component({
  selector: "esg-recommendation-card",
  templateUrl: "./recommendation-card.component.html",
  styleUrls: ["./recommendation-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationCardComponent {
  @Input() recommendation: Recommendation;
  @Input() companyScoreTitle: string;
  @Input() companyPrimaryColor: string;
  @Input() companySecondaryColor: string;
  @Input() industryScoreTitle: string;
  @Input() industryPrimaryColor: string;
  @Input() industrySecondaryColor: string;
  @Input() circleInnerText: string;

  @Output() actionLeverSelected = new EventEmitter<string>();

  showMore = false;
  showHint = false;
  maxInitialLength = 90;

  EsgScoringDataUtilities = EsgScoringDataUtilities;

  clickActionLever(actionLeverKey: string) {
    this.actionLeverSelected.emit(actionLeverKey);
  }

  onShow() {
    this.showMore = !this.showMore;
  }

  onShowHint() {
    this.showHint = !this.showHint;
  }
}
