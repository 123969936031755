import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { IModalConfirmation } from "@wtw/platform/interfaces";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { ScreenerSettingsExistModalData } from "./screener-settings-exist-modal-data";

@Component({
  selector: "esg-screener-settings-exist-modal",
  templateUrl: "./screener-settings-exist-modal.component.html",
  styleUrls: ["./screener-settings-exist-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenerSettingsExistModalComponent implements IModalConfirmation {
  data: ScreenerSettingsExistModalData;

  onClose = new Subject<boolean>();

  constructor(private bsModalRef: BsModalRef) {}

  show(): void {}

  close(loadRun: boolean) {
    this.onClose.next(loadRun);
    this.bsModalRef.hide();
  }

  toggleActiveRun(event:MatCheckboxChange) {
    this.data.setScreenerAsActiveForUser = event.checked;
  }
}
