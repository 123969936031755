import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "esg-score-slider",
  templateUrl: "./score-slider.component.html",
  styleUrls: ["./score-slider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreSliderComponent {
  @Input() badColorHex: string;
  @Input() mediumColorHex: string;
  @Input() goodColorHex: string;
  @Input() percent: number;
}
