import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from "@ngx-translate/core";
import { RunService } from "@wtw/platform/services";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { ESGPillar } from "src/app/api/generated/ESGPillar";

@Component({
  selector: "esg-theme-scoring",
  templateUrl: "./theme-scoring.component.html",
  styleUrls: ["./theme-scoring.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeScoringComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private runService: RunService,
    private router: Router
  ) {
    this.translateService.missingTranslationHandler = new class extends MissingTranslationHandler {
      handle(params: MissingTranslationHandlerParams): any {
        console.log('handle', params);
      }
    };
  }

  recommendationsTranslationKey =
    "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY_DETAIL.RECOMMENDATIONS";

  filterAction$ = new BehaviorSubject<number | null>(null);

  private _filters = [1, 2, 3, 4].map((tierId) => ({ tierId, selected: true })); //stores the current tier filter values

  viewModel$ = combineLatest([
    this.runService.activeRun, //The active run
    this.activatedRoute.url.pipe(map((r) => r[0])), // The theme url ("climate-risk-and-resilience" etc)
    this.activatedRoute.parent.url.pipe(map((r) => r[0])), // The pillar url ("environment" etc)
    this.filterAction$, // fires when user clicks any of the filters
  ]).pipe(
    map(([activeRun, themeUrl, pillarUrl, tierIdToToggle]) => {
      //toggle the filter
      if (tierIdToToggle) {
        const filter = this._filters.find((f) => f.tierId === tierIdToToggle);
        filter.selected = !filter.selected;
      }

      var pillar = activeRun.data.esgPillars.find(
        (s) => s.key.toLowerCase() == pillarUrl.path.toLocaleLowerCase()
      ) as ESGPillar;

      return {
        pillarKey: pillar.key,
        recommendations: pillar.themes
          .find(
            (theme) =>
              theme.key === themeUrl.path.toUpperCase().split("-").join("_")
          )
          ?.recommendations.map((recommendation) => {
            recommendation.poweredBy = activeRun.data.metricTypes.find(
              (metricType) =>
                metricType.items.some(
                  (metricTypeItem) =>
                    metricTypeItem.key === recommendation.metricTypeKey
                )
            ).source;

            return recommendation;
          }),
        paragraph: this.translateService.instant(
          "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY_DETAIL.THEMES." +
            themeUrl.path.toUpperCase() +
            ".PARAGRAPH"
        ),
        filters: this._filters,
        themeKey: themeUrl.path,
      };
    }),
    map((vm) => {
      // Add the counts and filter the reommendations
      return {
        totalCount: vm.recommendations?.length,
        filteredRecommendations: vm.recommendations?.filter((r) =>
          this._filters
            .filter((f) => f.selected)
            .map((f) => f.tierId)
            .includes(r.tier)
        ),
        paragraph: vm.paragraph,
        filters: vm.filters,
        pillarKey: vm.pillarKey,
        themeKey: vm.themeKey,
      };
    })
  );

  filterClicked(tierId: number) {
    this.filterAction$.next(tierId);
  }

  navigateToActionLever(
    pillarKey: string,
    actionLeverKey: string,
    themeKey: string
  ) {
    this.router.navigate(["../../../offerings", actionLeverKey.toLowerCase()], {
      relativeTo: this.activatedRoute,
      queryParams: {
        fromPillar: pillarKey.toLowerCase(),
        fromTheme: themeKey.toLowerCase(),
      },
    });
  }
}
