import { Score } from "src/app/api/generated";
import { RecommendationScore } from "src/app/api/generated/RecommendationScore";

/// Helper class for common functions which need to be applied to EsgScoring data
export class EsgScoringDataUtilities {

    // Sort esgPillars.performanceScores array by date
    static sortPillarPerformanceScoresByDate(performanceScores: Score[], direction: 'asc' | 'desc' ) : Score[]
    {
        return (direction==='desc') 
            ? performanceScores.sort((a, b) => b.date > a.date ? 1 : -1)
            : performanceScores.sort((a, b) => b.date > a.date ? -1 : 1)
    }

    // Legacy to get around the fact that some score are single
    static mapToRecommendationScore(score: number) {
      return [{ score: score } as RecommendationScore] as RecommendationScore[];
    }
}