import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "esg-pillar-selector",
  templateUrl: "./pillar-selector.component.html",
  styleUrls: ["./pillar-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillarSelectorComponent {
  @Input() pillars: pillarVM[];
  @Output() pillarSelected = new EventEmitter<pillarVM>();
}

export class pillarVM {
  text: string;
  key: string;
  selected: boolean;
  count: number;
}
