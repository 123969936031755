<esg-app-header
  [backlinkText]="'CURRENT_MODEL.GLOBAL.SEARCH' | translate"
  backlinkLocation="/home"
  [pageTitle]="executiveSummaryKey + '.PAGE_TITLE' | translate"
  [hideBreadcrumb]="isClient"
></esg-app-header>

<div class="screener-details"  *ngIf="screener$ | async as screener">
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayout.lt-sm="column" fxLayoutAlign.xs="start center " class="collapsible">
    {{  screener?.screenerName}}
    <a [ngClass]="{'hidden': !collapsiblePanel}" class="detail-link" (click)="expandPanel()" fxLayoutAlign="end center">
        {{
          "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY.SCREENER_DETAILS"
            | translate
        }}
    </a>
  </div>
  <div class="content" [ngClass]="{'hidden': collapsiblePanel}">
      <div fxLayout="row" class="screener-header" fxFlex>
        <div fxLayout="column" fxFlex>
          <div fxLayoutAlign="end center">
            <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <esg-screener-selector 
            *ngIf="screener$ | async as screener"
            [screener]="screener"
            (setAsActive)="setActiveScreener($event)"
          ></esg-screener-selector>
        </div>
      </div>
    </div>
  </div>
<div>
  <div fxLayout="row" *ngIf="viewModel$ | async as vm">
    <div fxFlex fxFlex.lt-lg="20px"></div>
    <div
      fxFlex.lt-lg
      fxFlex="1160px"
      fxLayout="column"
      fxLayoutGap="20px"
      fxLayoutGap.lt-sm="20px"
    >
      <div>
        <div
          fxLayout="row"
          fxLayoutAlign="space-between"
          fxLayout.lt-sm="column"
          fxLayoutAlign.xs="start center "
        >
          <h1>
            {{ executiveSummaryKey + ".HEADING" | translate }}
          </h1>

          <div
            class="download-link"
            (click)="downloadReportClick(vm.activeRun.runId)"
            fxLayout="row"
            fxLayoutAlign="center center"
            fxLayoutGap="10px"
          >
            <div>
              {{ executiveSummaryKey + ".DOWNLOAD_A_REPORT" | translate }}
            </div>
            <img src="/assets/images/download-pdf-blue.svg" />
          </div>
          <div fxHide.gt-xs fxFlex="25px"></div>
        </div>

        <p [innerHTML]="executiveSummaryKey + '.PARAGRAPH' | translate"></p>

        <div
          fxLayout="row"
          fxLayout.lt-sm="column"
          fxLayoutAlign="center"
          fxLayoutGap="10px"
          fxLayoutGap.lt-sm="20px"
        >
          <div
            *ngFor="let pillar of vm.activeRun.data?.esgPillars"
            [ngClass.lt-sm]="'padded'"
          >
            <esg-score-card
              [title]="
                'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' + pillar.key + '.NAME'
                  | translate
              "
              [score]="pillar.score"
              [secondaryText]="
                executiveSummaryKey + '.CIRCLE_SECONDARY_TEXT' | translate
              "
              [benchmarkPercent]="pillar.percentile"
              [colorHex]="
                'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' +
                  pillar.key +
                  '.PRIMARY_COLOR' | translate
              "
              [secondaryColorHex]="
                'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' +
                  pillar.key +
                  '.SECONDARY_COLOR' | translate
              "
              [benchmarkText]="
                executiveSummaryKey + '.BENCHMARK_EXPLANATION'
                  | translate
                    : {
                        percentile: pillar.percentile
                      }
              "
              [quartile]="'CURRENT_MODEL.GLOBAL.QUARTILES.' + pillar.quartile | translate"
            >
            </esg-score-card>

            <div class="link">
              <a
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="6px"
                [routerLink]="pillar.key.toLowerCase()"
                ><div>
                  {{
                    executiveSummaryKey + ".ESG_PILLAR_LINK_TEXT" | translate
                  }}
                </div>
                <span class="icon-back-arrow-2 reverse"></span
              ></a>
            </div>
          </div>
        </div>
      </div>
      <!--Company Comparison-->
      <esg-comparison-companies [comparisonCompanies]="vm.comparisonCompanies" [pillars]="vm.pillars" ></esg-comparison-companies>

      <!--Benchmarking-->
      <div fxLayout="row" fxFlex fxLayoutGap="20px" fxLayout.lt-md="column">
        <div fxLayout="column" fxFlex fxLayoutGap="20px">
          <esg-card
            [heading]="benchmarkingTranslationKey + '.HEADING' | translate"
            [paragraph]="
              benchmarkingTranslationKey + '.TEXT'
                | translate
                  : {
                      ClientName: vm.client.displayName,
                      peerCompanyCount: vm.peerCompanyCount,
                      ComparisonIndustry: vm.industry?.description,
                      EmployeeBucket: vm.employeeBucket?.description ?? 'All',
                      RevenueBucket: vm.revenueBucket?.description ?? 'All'
                    }
            "
            linkLocation="../customize"
            [linkText]="benchmarkingTranslationKey + '.LINK_TEXT' | translate"
          >
          </esg-card>
          <esg-card
            fxFlex
            [heading]="riskMetricsTranslationKey + '.HEADING' | translate"
            [paragraph]=""
            linkLocation="../customize"
            [linkText]="riskMetricsTranslationKey + '.LINK_TEXT' | translate"
          >
            <esg-risk-metrics
              [metricTypes]="vm.activeRun.data?.metricTypes"
              [metricSources]="vm.metricSources"
            ></esg-risk-metrics>
          </esg-card>
        </div>

        <div fxFlex.gt-sm="33%">
          <esg-card
            fxFlex
            [heading]="leversTranslationKey + '.HEADING' | translate"
            [paragraph]="leversTranslationKey + '.TEXT' | translate  : {
              ClientName: vm.client.displayName }"
            linkLocation="../offerings"
            [linkText]="leversTranslationKey + '.LINK_TEXT' | translate"
          >
            <esg-action-levers [riskOfferings]="vm.riskOfferings" [pillars]="vm.pillars"></esg-action-levers>
          </esg-card>
        </div>
      </div>
    </div>
    <div fxFlex fxFlex.lt-lg="20px"></div>
  </div>
</div>
