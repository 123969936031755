import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs";

@Component({
  selector: "esg-next-previous",
  templateUrl: "./next-previous.component.html",
  styleUrls: ["./next-previous.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextPreviousComponent {
  constructor(private activatedRoute: ActivatedRoute) {}

  @Input() showCurrent = true;

  viewModel$ = this.activatedRoute.url.pipe(
    map((url) => {
      const syblingRoutes = this.activatedRoute.parent.routeConfig.children
        .filter((child) => child.path !== "")
        .filter((child) => !child.path.startsWith("~"));
      const currentRouteIndex: number = syblingRoutes.findIndex(
        (s) => s.path === url[0].path
      );
      return {
        current: syblingRoutes[currentRouteIndex],
        previous:
          currentRouteIndex === 0 ? null : syblingRoutes[currentRouteIndex - 1],
        next:
          currentRouteIndex === syblingRoutes.length
            ? null
            : syblingRoutes[currentRouteIndex + 1],
      };
    })
  );
}
