<div fxLayout="col" class="pillar-tabs" [ngClass.gt-sm]="'tm-20'">
  <ol fxLayout="row" fxLayoutAlign="end center">
    <li class="pillar-tab" [ngClass.lt-md]="'small-tab'"
      [routerLink]="[]"
      [routerLinkActiveOptions]="{ exact: true }"
      [routerLinkActive]="'selected'"
    >
    <span fxFlex.gt-sm="200px" [ngClass.lt-md]="'small-tab'">All</span>
    </li>
    <li class="pillar-tab" [ngClass.lt-md]="'small-tab'"
      [routerLink]="[]"
      [queryParams]="{ filter: 'IS_ENVIRONMENT' }"
      [routerLinkActive]="'selected'"
    >
    <span fxFlex.gt-sm="200px" [ngClass.lt-md]="'small-tab'">
      {{ 'CURRENT_MODEL.GLOBAL.ESG_PILLARS.ENVIRONMENT.NAME' | translate }}
    </span>
    </li>
    <li class="pillar-tab" [ngClass.lt-md]="'small-tab'"
      [routerLink]="[]"
      [queryParams]="{ filter: 'IS_SOCIAL' }"
      [routerLinkActive]="'selected'"
    >
    <span fxFlex.gt-sm="200px" [ngClass.lt-md]="'small-tab'">
      {{ 'CURRENT_MODEL.GLOBAL.ESG_PILLARS.SOCIAL.NAME' | translate }}
    </span>
    </li>
    <li class="pillar-tab" [ngClass.lt-md]="'small-tab'"
      [routerLink]="[]"
      [queryParams]="{ filter: 'IS_GOVERNANCE' }"
      [routerLinkActive]="'selected'"
    >
    <span fxFlex.gt-sm="200px" [ngClass.lt-md]="'small-tab'">
      {{ 'CURRENT_MODEL.GLOBAL.ESG_PILLARS.GOVERNANCE.NAME' | translate }}
    </span>
    </li>  
  </ol>
</div>
