import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ClientService, RunService } from "@wtw/platform/services";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { ButtonMenuItemVM } from "src/app/shared/components/button-menu/button-menu.component";

@Component({
  selector: "esg-my-performance-score",
  templateUrl: "./my-performance-score.component.html",
  styleUrls: ["./my-performance-score.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyPerformanceScoreComponent {
  graphTypeEnum = GraphTypeEnum;
  moment = moment;

  translationKey =
    "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY_DETAIL.THEMES.MY-PERFORMANCE-SCORE";

  buttonMenuItems = [
    {
      text: this.translateService.instant(
        this.translationKey + ".GRAPHS.SCORE.BUTTON_TEXT"
      ),
      key: GraphTypeEnum[GraphTypeEnum.score],
      selected: true,
    },
    {
      text: this.translateService.instant(
        this.translationKey + ".GRAPHS.BENCHMARKING.BUTTON_TEXT"
      ),
      key: GraphTypeEnum[GraphTypeEnum.benchmark],
    },
  ] as ButtonMenuItemVM[];

  private selectGraphTypeKeyAction$ = new BehaviorSubject<GraphTypeEnum>(
    GraphTypeEnum.score
  );

  constructor(
    private runService: RunService,
    private activatedRoute: ActivatedRoute,
    private clientService: ClientService,
    private translateService: TranslateService
  ) {}

  viewModel$ = combineLatest([
    this.runService.activeRun,
    this.activatedRoute.parent.url,
    this.clientService.currentClientChanged,
    this.selectGraphTypeKeyAction$,
  ]).pipe(
    map(([activeRun, url, client, selectedGraphType]) => {
      const pillar = activeRun.data.esgPillars.find(
        (s) => s.key.toLowerCase() == url[0].path.toLocaleLowerCase()
      );

      const sortedPerformanceScores = pillar.performanceScores.sort((a, b) =>
        b.date > a.date ? -1 : 1
      );

      return {
        client,
        performanceScores: sortedPerformanceScores,
        latestPerformanceScore:
          sortedPerformanceScores[sortedPerformanceScores.length - 1],
        selectedGraphType,
        pillar,
      };
    })
  );

  // Switch graph type
  switchGraph(selectedGraphType: ButtonMenuItemVM) {
    // Reset selected button
    this.buttonMenuItems.forEach((element) => {
      element.selected = false;
      if (element.key === selectedGraphType.key) {
        element.selected = true;
      }
    });
    this.selectGraphTypeKeyAction$.next(GraphTypeEnum[selectedGraphType.key]);
  }
}

export enum GraphTypeEnum {
  score,
  benchmark,
}
