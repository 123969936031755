import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "esg-offerings-filter",
  templateUrl: "./offerings-filter.component.html",
  styleUrls: ["./offerings-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferingsFilterComponent {
  constructor() {}
}
