<esg-app-header
  [backlinkText]="
    'CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY.PAGE_TITLE' | translate
  "
  [backlinkLocation]="'../'"
  [pageTitle]="'CURRENT_MODEL.PAGES.OFFERINGS.PAGE_TITLE' | translate"
></esg-app-header>

<div *ngIf="viewModel$ | async as vm">
  <div *ngIf="!vm.fromPillarKey && !vm.fromThemeKey">
  <div fxLayout="row">
    <div fxFlex fxFlex.lt-lg="20px"></div>
    <div fxFlex.gt-md="1160px">
      <div fxFlex>
        <h1>
          {{ offeringsTranslationKey + "PAGE_HEADING" | translate }}
        </h1>
        <p class="intro">
          {{ offeringsTranslationKey + "HEADING_PARAGRAPH" | translate : {ClientName: vm.clientName}  }}
        </p>
      </div>  
    </div>
    <div fxFlex fxFlex.lt-lg="20px"></div>
  </div>
  <div fxLayout="row">
    <div fxFlex fxFlex.lt-lg="20px"></div>
    <div fxFlex.gt-md="1160px">
        <!-- If large screen, actionlevers filter shown here-->
        <esg-offerings-filter [ngStyle.gt-md]="'margin-top:20px'" fxHide.lt-md fxFlex></esg-offerings-filter>
        <div fxFlex class="pillar-tab-flexed"></div>
    </div>
    <div fxFlex fxFlex.lt-lg="20px"></div>
  </div>

  <div fxLayout="row" class="mb-20">
    <div fxFlex fxFlex.lt-lg="20px" fx></div>
    <div fxFlex.gt-md="1160px">
      <p *ngIf="!vm.isFiltered" class="intro">
        {{ offeringsTranslationKey + "THEMES_PARAGRAPH" | translate }}
      </p>
      <p *ngIf="vm.isFiltered" class="intro">
        {{ offeringsTranslationKey + "THEMES_PARAGRAPH_WITH_ORDERED_THEMES" | translate }}
        <br />
        {{ offeringsTranslationKey + "ORDERED_THEMES" | translate : 
        { 
          Theme1: (vm.orderedThemes[0]) ? vm.orderedThemes[0] : '',  
          Theme2: (vm.orderedThemes[1]) ? ', ' + vm.orderedThemes[1] : '', 
          Theme3: (vm.orderedThemes[2]) ? ', ' + vm.orderedThemes[2] : '', 
          Theme4: (vm.orderedThemes[3]) ? ', ' + vm.orderedThemes[3] : ''
        }
      }}
      </p> 
      <div fxFlex class="pillar-tab-flexed"></div>
    </div>
    <div fxFlex fxFlex.lt-lg="20px"></div>
  </div>
</div>

<div fxLayout="row">
  <div fxFlex fxFlex.lt-lg="20px"></div>
  
  <div
    fxFlex.gt-md="1160px"
    fxLayout="row"
    fxLayout.lt-md="column"
    
  >
    <div style="position: relative;" ngClass.lt-sm="extra-small">
      <div  
        class="back-to-theme-box" ngClass.lt-md="fill"
        *ngIf="vm.fromPillarKey && vm.fromThemeKey"
      >
        <a
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          [routerLink]="
            '../executive-summary/' + vm.fromPillarKey + '/' + vm.fromThemeKey
          "
          ><span class="icon-back-arrow-2"></span>
          <span
            >Back to
            {{ themesKey + vm.fromThemeKey.toUpperCase() | translate }}</span
          ></a
        >
      </div>
      <div *ngIf="!vm.fromPillarKey && !vm.fromThemeKey">
        <a
          class="change-lever"
          (click)="toggleMenu()"
          fxHide.gt-sm
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
          ><span>{{
            offeringsTranslationKey + "SELECT_LEVER" | translate
          }}</span>
          <span class="icon-back-arrow-2 reverse"></span
        ></a>

        <div
          ngClass.lt-md="dropdown"
          [class.show-dropdown]="vm.showDropdownMenu"
          fxLayout="column"
        >
        <!-- If large screen, actionlevers filter shown here-->
        <esg-offerings-filter [ngStyle.gt-sm]="'display:none'" fxFlex></esg-offerings-filter>
          <ol class="offerings" fxLayout="column">
            <li
              (click)="closeMenu()"
              *ngFor="let lever of vm.offerings"
              [routerLink]="lever.key.toLowerCase()"
              [routerLinkActive]="'selected'"
              queryParamsHandling="merge"
            >
              {{ lever.NAME }}
            </li>
          </ol>
          <div fxFlex="15px"></div>
          <button
            class="btn btn-default"
            (click)="seeMore()"
            *ngIf="vm.totalCount > vm.offerings.length"
          >
            {{ offeringsTranslationKey + "SEE_MORE" | translate }}
          </button>
          <button
            class="btn btn-default"
            (click)="seeLess()"
            *ngIf="vm.offerings.length > vm.defaultOfferingsCount"
          >
            {{ offeringsTranslationKey + "SEE_LESS" | translate }}
          </button>
        </div>
      </div>
    </div>

    <div fxFlex="20px" fxHide.lt-md></div>

    <div fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
  <div fxFlex fxFlex.lt-lg="20px"></div>
</div>
</div>
