<div
  fxFlex
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutGap="50px"
  fxLayout.lt-md="column"
>
  <div><img src="/assets/images/lego-D.svg" /></div>
  <div>
    <div class="title">
      {{ "CURRENT_MODEL.GLOBAL.COMING_SOON.TITLE" | translate }}
    </div>
    <div class="paragraph">
      <div
        [innerHTML]="'CURRENT_MODEL.GLOBAL.COMING_SOON.PARAGRAPH' | translate"
      ></div>

      <div *ngIf="email$ | async as email">
        <a [href]="'mailto:' + email"
          >{{ "CURRENT_MODEL.GLOBAL.COMING_SOON.ENQUIRE" | translate
          }}<span class="icon-back-arrow-2"></span
        ></a>
      </div>
    </div>
  </div>
</div>
