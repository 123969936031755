<div
  fxFlex
  fxLayout="row"
  fxLayout.lt-lg="column"
  fxLayoutGap.lt-lg="30px"
  *ngIf="viewModel$ | async as vm"
>
  <div
    [fxFlex]="'calc(50% + ' + labelWidthPixels / 2 + 'px)'"
    fxFlex.lt-lg
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <div
      class="graph-label"
      [style.paddingLeft.px]="
        vm.extraSmall ? labelWidthPixelsSmall : labelWidthPixels
      "
    >
      {{ scoreHeading }}
    </div>
    <esg-graph-bar
      *ngFor="let pi of lever.positiveImpacts"
      [heightPixels]="vm.extraSmall ? barHeightPixelsSmall : barHeightPixels"
      [labelWidthPixels]="
        vm.extraSmall ? labelWidthPixelsSmall : labelWidthPixels
      "
      [percentageValue]="pi.score"
      [percentageLabel]="pi.score"
      [label]="globalLOBKey + pi.key | translate"
      [backgroundColor]="barBackgroundColor"
      [barColor]="barColor"
      [percentLabelColor]="barPercentLabelColor"
      [offsetPercentLabelColor]="barOffsetPercentLabelColor"
    ></esg-graph-bar>
  </div>

  <div
    [fxFlex]="'calc(50% - ' + labelWidthPixels / 2 + 'px)'"
    [fxFlex.lt-lg]="'calc(50% + ' + labelWidthPixels / 2 + 'px)'"
    fxLayout="column"
    fxLayoutGap="10px"
  >
    <div
      class="graph-label"
      [style.paddingLeft.px]="
        vm.lessThanLarge
          ? vm.extraSmall
            ? labelWidthPixelsSmall
            : labelWidthPixels
          : 10
      "
    >
      {{ benchmarkHeading }}
    </div>
    <esg-graph-bar
      *ngFor="let pi of lever.positiveImpacts"
      [heightPixels]="vm.extraSmall ? barHeightPixelsSmall : barHeightPixels"
      [labelWidthPixels]="
        vm.lessThanLarge
          ? vm.extraSmall
            ? labelWidthPixelsSmall
            : labelWidthPixels
          : 1
      "
      [percentageValue]="pi.benchmarkScore"
      [percentageLabel]="pi.benchmarkScore"
      [label]="vm.lessThanLarge ? (globalLOBKey + pi.key | translate) : ''"
      [backgroundColor]="barBackgroundColor"
      [barColor]="barColor"
      [percentLabelColor]="barPercentLabelColor"
      [offsetPercentLabelColor]="barOffsetPercentLabelColor"
    ></esg-graph-bar>
  </div>
</div>
