import { DownloadInfo } from './../../shared/components/download-modal/download-info';
import { EsgAppInsightsService } from './../../services/esg-app-insights-service';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  ClientService,
  ModalService,
  RunService,
  UserService,
} from "@wtw/platform/services";
import {
  combineLatest,
  exhaustMap,
  map,
  Observable,
  of,
  startWith,
  Subject,
  Subscription,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { ScreenerListItem } from "src/app/api/generated";
import { ScreenersProxy } from "src/app/api/ScreenersController";
import { ReferenceDataService } from "src/app/services/reference-data.service";
import { DownloadModalData } from "src/app/shared/components/download-modal/download-modal-data";
import { DownloadModalComponent } from "../../shared/components/download-modal/download-modal.component";
import { ScreenersService } from "src/app/services/screeners-service";
import { Router } from "@angular/router";
import { ReferenceDataProxy } from 'src/app/api/ReferenceDataController';

@Component({
  selector: "esg-executive-summary",
  templateUrl: "./executive-summary.component.html",
  styleUrls: ["./executive-summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExecutiveSummaryComponent implements OnInit, OnDestroy {
  constructor(
    private runsService: RunService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private clientService: ClientService,
    private userService: UserService,
    private referenceDataService: ReferenceDataService,
    private screenersProxy: ScreenersProxy,
    private runService: RunService,
    private screenersService: ScreenersService,
    private router: Router,
    private esgAppInsightsService: EsgAppInsightsService,
    private referenceDataProxy: ReferenceDataProxy
  ) {}

  subscriptions = new Subscription();

  ngOnInit(): void {
    this.subscriptions.add(this.runLatestActionComplete$.subscribe());
    this.showNotificationSpinner(this.translateService.instant(this.screenerManagementKey + ".MESSAGES.GETTINGLATESTDATA"));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private retrievingMessage = this.translateService.instant("CURRENT_MODEL.GLOBAL.MESSAGES.RETRIEVING_REFERENCE_DATA");
  private screenerManagementKey = "CURRENT_MODEL.PAGES.SCREENER_MANAGEMENT";
  private setScreenerActiveAction$ = new Subject<ScreenerListItem>();
  private runLatestAction$ = new Subject<ScreenerListItem>();
  private rescoreNotification$: Observable<boolean>;

  executiveSummaryKey = "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY";
  benchmarkingTranslationKey = this.executiveSummaryKey + ".COMPONENTS.BENCHMARKING_SELECTIONS";
  riskMetricsTranslationKey = this.executiveSummaryKey + ".COMPONENTS.RISK_METRIC_SELECTIONS";
  leversTranslationKey = this.executiveSummaryKey + ".COMPONENTS.WTW_LEVERS";
  riskMetricsKey = "CURRENT_MODEL.GLOBAL.RISK_METRICS.";
  isClient = this.userService.currentUserInfo.isClient;

  collapsiblePanel = true;
  
  private metricSourceItemFilter = [
    this.translateService.instant(this.riskMetricsKey + "WTW.NAME"), 
    this.translateService.instant(this.riskMetricsKey + "ANILINE.NAME"), 
    this.translateService.instant(this.riskMetricsKey + "POLECAT.NAME"), 
    this.translateService.instant(this.riskMetricsKey + "MSCI.NAME")]
      .map((item) => { return item.toUpperCase();
    });

  private setScreenerActiveComplete$ = this.setScreenerActiveAction$.pipe(
    withLatestFrom(this.clientService.currentClientChanged),
    exhaustMap(([screenerListItem, client]) => {
      return this.screenersProxy
        .updateActiveScreener(screenerListItem.runId, client.clientId)
        .uiSignal({ uiLabel: "Setting as Active Screener", debugInfo: "" });
    })
  );

  screener$ = combineLatest([
    this.runService.activeRun,
    this.setScreenerActiveComplete$.pipe(startWith(true)),
  ]).pipe(
    switchMap(([run, _]) => 
      this.screenersProxy.screener(run.runId)
        .pipe(map((result) => {
          if(result.data.newerDataAvailable) {
            this.runLatestAction$.next(result.data);
          }
          else {
            this.hideNotificationSpinner();
          }
          return result.data
        }))
    )
  );

  runLatestActionComplete$ = this.runLatestAction$.pipe(
    exhaustMap((screener) => {
      const clientId = this.clientService.currentClient.clientId;
      // Call screener service.
      // This will return either a new run id or an existing run id in the event there was an error
      return this.screenersService
        .requestNewScreenerCreateDataJob(clientId, screener.screenerId)
        .pipe(
          switchMap((jobKeyResponse) => {
            // If we got a job key back then start polling the job check status
            // We are expecting the RContainer/Orchestration function to output an MDS file and set job to "Success" or "Failure"
            return this.screenersService
              .pollScreenerDataReady(
                jobKeyResponse.data,
                screener.runId,
                clientId,
                screener.screenerId
              )
              .pipe(
                map((runId) => {
                  this.hideNotificationSpinner();
                  this.router.navigateByUrl(
                    "/run/" + runId + "/executive-summary"
                  );
                })
              );
          })
        )
    })
  );

  viewModel$ = combineLatest([
    this.runsService.activeRun,
    this.clientService.currentClientChanged,
    combineLatest([
      this.referenceDataService.pics6Industries$,
      this.referenceDataService.employeeBuckets$,
      this.referenceDataService.revenueBuckets$,
    ]).uiSignal({
      debugInfo: this.retrievingMessage,
      uiLabel: this.retrievingMessage,
    }),
  ]).pipe(
    switchMap(
      ([
        activeRun,
        client,
        [industries, employeeBuckets, revenueBuckets],
      ]) => {
        const industry = industries.find((i) => i.picS6 == activeRun.data.benchmarkingSelections[0].industryPICS6);
        const employeeBucket = employeeBuckets.find((e) => e.id == activeRun.data.benchmarkingSelections[0].employeeBucketId);
        const revenueBucket = revenueBuckets.find((r) => r.id == activeRun.data.benchmarkingSelections[0].revenueBucketId)

        return this.referenceDataProxy.getPeerCompanyCount(industry.picS6, +employeeBucket, +revenueBucket).pipe(
          map((peerCompanyCount) => {
            return {           
              activeRun,
              client,
              metricSources: activeRun.data?.metricSources?.filter(
                (item) => this.metricSourceItemFilter.includes(item)
              ),
              industry: industry,
              employeeBucket: employeeBucket,
              revenueBucket: revenueBucket,
              pillars: activeRun.data.esgPillars,
              riskOfferings: activeRun.data.riskOfferings,
              peerCompanyCount: peerCompanyCount.data,
              comparisonCompanies: activeRun.data.comparisonCompanies 
            };
          }))
      }
    )
  );

  downloadReportClick(runId: number) {
    // Mark the run as complete in appinsights
    this.esgAppInsightsService.markRunAsComplete();

    // Based on the user role, build a list of downloadable reports
    const reportDownloadList = this.setRportVisibilityBasedOnUserRole(
      this.translateService.instant("CURRENT_MODEL.GLOBAL.DOWNLOADS") as DownloadInfo[]
    );

    const data = {
      heading: this.translateService.instant(
        this.executiveSummaryKey + ".DOWNLOAD_MODAL.HEADER"
      ),
      paragraph: this.translateService.instant(
        this.executiveSummaryKey + ".DOWNLOAD_MODAL.PARAGRAPH"
      ),
      closeButtonText: this.translateService.instant(
        this.executiveSummaryKey + ".DOWNLOAD_MODAL.CLOSE_BUTTON"
      ),
      downloadButtonText: this.translateService.instant(
        this.executiveSummaryKey + ".DOWNLOAD_MODAL.DOWNLOAD_BUTTON"
      ),
      downloads: reportDownloadList,
      runId,
    } as DownloadModalData;

    this.modalService.openWithComponent(DownloadModalComponent, { data });
  }

  setRportVisibilityBasedOnUserRole(reportDownloadList: DownloadInfo[]) : DownloadInfo[] {
    reportDownloadList.forEach(report => {
      if(report.HIDE_DOWNLOAD_FROM) {
        (report.HIDE_DOWNLOAD_FROM?.some(role => role === this.userService.currentUserInfo.role)) 
          ? report.isDisabled = true 
          : report.isDisabled = false
      }
      else {
        report.isDisabled = false
      }
    });

    return reportDownloadList;
  }
  
  topN(list: any[], n) {
    return list.slice(0, n);
  }

  setActiveScreener(screener: ScreenerListItem) {
    this.setScreenerActiveAction$.next(screener);
  }
  private showNotificationSpinner(spinnerMessage: string): void {
    this.rescoreNotification$ = new Observable<boolean>();
    this.rescoreNotification$ = of(false).uiSignal({
      debugInfo: spinnerMessage,
      uiLabel: spinnerMessage,
    });
  }

  private hideNotificationSpinner(): void {
    if (this.rescoreNotification$) {
      this.rescoreNotification$.subscribe((_) => null).unsubscribe();
      this.rescoreNotification$ = null;
    }
  }

  expandPanel() {
    this.collapsiblePanel = false;
  }

  close() {
    this.collapsiblePanel = true;
 }
}
