<div *ngIf="company" fxLayout="row" fxFlex>
    <div fxLayout="column" fxLayoutGap="10px">
        <div class="companyName" fxFlex>
            {{ company.name }}
        </div>
        <div fxLayout="row" fxLayoutGap="10px">
            <div *ngFor="let pillar of company.esgPillars" fxLayout="row">
                <div class="{{'score-bubble ' + pillar.key}}">
                    <span [ngClass]="pillarArrow(pillar.key)"></span>
                    <span>{{ pillarScore(pillar.key) }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!company"  id="customise-link"
    fxLayout="row" fxFlex 
    fxLayoutGap="20px" fxLayout.lt-md="column" 
    [routerLink]="['../customize']">
    <div fxLayout="column" fxFlex fxLayoutGap="20px" id="no-companies-selected" fxLayoutAlign="center center">
        <span class="icon-plus-circle icon"></span>
        <p *ngIf="companyCount<=0">
            {{ companyComparisonTranslationKey + ".NO_COMPANIES_SELECTED" | translate }}
        </p>
    </div>
</div>