import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { ScreenerListItem } from "src/app/api/generated";

@Component({
  selector: "esg-screener-selector",
  templateUrl: "./screener-selector.component.html",
  styleUrls: ["./screener-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenerSelectorComponent {
  constructor(private router: Router) {}

  @Input() screener: ScreenerListItem;
  @Output() setAsActive = new EventEmitter<ScreenerListItem>();
  
  executiveSummaryKey = "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY";

  setAsActiveScreener(screener: ScreenerListItem) {
    this.setAsActive.emit(screener);
  }

  changeAsOf(runId: any) {
    this.router.navigate(["/run", runId]);
  }
}
