import { ESGPillar } from './../../../api/generated/ESGPillar';
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RunService } from "@wtw/platform/services";
import { combineLatest, map } from "rxjs";
import { ActionLever } from "src/app/api/generated";
import { EsgAppInsightsService } from "src/app/services/esg-app-insights-service";
import { AppInsightsEventAction } from "src/app/shared/enums/app-insights-event-action";
import { AppInsightsEventCategory } from "src/app/shared/enums/app-insights-event-category";

@Component({
  selector: "esg-offering",
  templateUrl: "./offering.component.html",
  styleUrls: ["./offering.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferingComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private runService: RunService,
    private router: Router,
    private esgAppInsightsService: EsgAppInsightsService
  ) {}

  actionLeversKey = "CURRENT_MODEL.PAGES.OFFERINGS.";
  globalActionLeversKey = "CURRENT_MODEL.GLOBAL.ACTION_LEVERS.";
  globalRiskMetricKey = "CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS.";

  AppInsightsEventAction = AppInsightsEventAction;
  AppInsightsEventCategory = AppInsightsEventCategory;

  viewModel$ = combineLatest([
    this.activatedRoute.url, // Last part of url contains offering key
    this.runService.activeRun,
    this.activatedRoute.queryParams
  ]).pipe(
    map(([url, activeRun, queryParams]) => {
      const allActionLevers = activeRun.data.riskOfferings.find((offering) => offering.key === 'ALL').actionLevers as ActionLever[];
      
      if (!url.length) {
        var defaultActionLeverRoute = (queryParams['filter'])
        ? this.pillarTopThemeActionLever(activeRun.data.esgPillars, queryParams['filter'])
        : allActionLevers[0].key.toLowerCase();
        
        this.router.navigate(
          ["./" + defaultActionLeverRoute],
          {
            queryParamsHandling: "preserve",
            relativeTo: this.activatedRoute
          }
        );
      } else {
        const lever = allActionLevers.find(
          (a) => a.key.toLowerCase() === url[0]?.path.toLocaleLowerCase()
        );

        // TEMP until abhishek fixes null in data
        lever.gaps = lever.gaps.filter((g) => g.key != null);
        lever.strengths = lever.strengths.filter((g) => g.key != null);

        return { lever, runId: activeRun.runId };
      }
    })
  );

  topN(list: any[], n) {
    return list.slice(0, n);
  }

  logInsight(rundId: number, eventCategory: string, eventAction: string) {
    this.esgAppInsightsService.logInsight(rundId, eventCategory, eventAction);
 }

 pillarTopThemeActionLever(pillars: ESGPillar[], filter: string) : string {
  const filteredBy = filter.toLowerCase().replace('is_', '');
  const pillar = pillars.find(p => p.key.toLowerCase() === filteredBy);
  return pillar.themes.find(s=>s.index === 1).recommendations[0].action_lever[0].toLowerCase();
 }
}
