import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ReferenceDataProxy } from "../api/ReferenceDataController";

@Injectable({
  providedIn: "root",
})
export class ReferenceDataService {
  constructor(private referenceDataProxy: ReferenceDataProxy) {}

  pics6Industries$ = this.referenceDataProxy.pics6Industries().pipe(
    map((result) => {
      return result.data;
    })
  );

  employeeBuckets$ = this.referenceDataProxy.employeeBuckets().pipe(
    map((result) => {
      return result.data;
    })
  );

  revenueBuckets$ = this.referenceDataProxy.revenueBuckets().pipe(
    map((result) => {
      return result.data;
    })
  );
}
