import { ApiResult, ProxyHttp } from "@wtw/platform/api";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { HttpResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AppInsightsProxy {
  
  constructor(private _phttp: ProxyHttp) {}

  // Insert RunCompleted event
  // A completion event will be raised for each user who views it
  markRunCompleted(runId: number) {
    return this._phttp.post(false, `/appinsights/run/${runId}/runcompleted`, {})
      .pipe(
        map((response) => {
          return { data: response.body, originalResponse: response }
        })
      )
  }

  // Check whether the screener/run has been marked as complete
  // A completion event will be raised for each user who views it
  checkRunCompleted(runId: number) : Observable<ApiResult<boolean>> {
    return this._phttp.get(false, `/appinsights/run/${runId}/runcompleted`, {})
      .pipe(
      map((ret: HttpResponse<boolean>) => {
        return <ApiResult<boolean>>{
          data: ret.body,
          originalResponse: ret,
        };
      })
    );
  }
}
