<ng-container *ngIf="viewModel$ | async as vm">
  <esg-app-header
    [backlinkText]="
      'CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY.PAGE_TITLE' | translate
    "
    [backlinkLocation]="'../../'"
    [pageTitle]="
      'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' + vm.pillar.key + '.NAME' | translate
    "
  ></esg-app-header>
  <div fxLayout="row">
    <div fxFlex fxFlex.lt-lg="20px"></div>
    <div fxFlex.lt-lg fxFlex="1020px">
      <esg-next-previous [showCurrent]="false"></esg-next-previous>
      <div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutAlign="start start"
        fxLayoutAlign.lt-md="start center"
        fxLayoutGap="40px"
        fxLayoutGap.lt-md="0px"
      >
        <div fxFlex>
          <h1>
            {{
              "CURRENT_MODEL.GLOBAL.ESG_PILLARS." + vm.pillar.key + ".HEADING"
                | translate
            }}
          </h1>
          <p class="intro">
            {{
              "CURRENT_MODEL.GLOBAL.ESG_PILLARS." + vm.pillar.key + ".PARAGRAPH"
                | translate
            }}
          </p>
        </div>
        <div fxHide.lt-sm>
          <esg-score-card
            style="margin: 30px 0; width: 200px"
            [showColorBar]="false"
            [title]="
              'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' + vm.pillar.key + '.NAME'
                | translate
            "
            [score]="vm.pillar.score"
            [secondaryText]="
              'CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY.CIRCLE_SECONDARY_TEXT'
                | translate
            "
            [benchmarkPercent]="vm.pillar.percentile"
            [colorHex]="
              'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' +
                vm.pillar.key +
                '.PRIMARY_COLOR' | translate
            "
            [secondaryColorHex]="
              'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' +
                vm.pillar.key +
                '.SECONDARY_COLOR' | translate
            "
            [benchmarkText]="
              'CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY.BENCHMARK_EXPLANATION'
                | translate
                  : {
                      percentile: vm.pillar.percentile
                    }
            "

            [quartile]="'CURRENT_MODEL.GLOBAL.QUARTILES.' + vm.pillar.quartile | translate"
          >
          </esg-score-card>
        </div>
      </div>
      <esg-tabs fxHide.lt-md [tabs]="vm.tabs"></esg-tabs>
      <router-outlet></router-outlet>
    </div>
    <div fxFlex fxFlex.lt-lg="20px"></div>
  </div>
</ng-container>
