<ng-container *ngIf="viewModel$ | async as vm">
  <div fxLayout="column" *ngIf="vm.lever">
    <div class="top-box">
      <h1>
        {{ actionLeversKey + "ABOUT" | translate : { Lever: globalActionLeversKey + vm.lever.key + ".NAME" | translate } }}
      </h1>
      <p>
        {{ globalActionLeversKey + vm.lever.key + ".DESCRIPTION" | translate }}
      </p>
    </div>

    <div fxFlex="20px"></div>

    <div fxLayoutAlign="space-between" fxLayout="column">
      <div
        fxLayout="column"
        fxLayoutAlign="start end"
        fxLayoutAlign.lt-sm="start stretch"
      >
        <a
          class="learn-more"
          target="_blank"
          [href]="globalActionLeversKey + vm.lever.key + '.LINK' | translate"
          (click)="logInsight(vm.runId, AppInsightsEventCategory.CLIENT_RUNS, AppInsightsEventAction.ENQUIRYMADE)"
        >
          {{ actionLeversKey + "LAUNCH" | translate : { Lever: globalActionLeversKey + vm.lever.key + ".NAME" | translate } }}
        </a>
        <div fxFlex="10px"></div>
        <a
          class="enquire-now"
          [href]="'mailto:' + (globalActionLeversKey + vm.lever.key + '.CONTACT_EMAIL' | translate)"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
          (click)="logInsight(vm.runId, AppInsightsEventCategory.CLIENT_RUNS, AppInsightsEventAction.LEARNMORECLICKED)"
          ><span>{{ actionLeversKey + "ENQUIRE" | translate }}</span>
          <span class="icon-back-arrow-2"></span
        ></a>
      </div>

      <div fxFlex="20px"></div>

      <div class="improve">
        {{ actionLeversKey + "HEADING" | translate : { Lever: globalActionLeversKey + vm.lever.key + ".NAME" | translate } }}
      </div>
    </div>

    <div fxFlex="30px"></div>

    <esg-numbered-box
      number="1"
      [title]="actionLeversKey + 'GAPS' | translate : { Lever: globalActionLeversKey + vm.lever.key + '.NAME' | translate }">
      <div
        fxLayout="row"
        fxLayoutGap="20px"
        fxLayout.md="column"
        fxLayout.sm="row"
        fxLayout.lt-sm="column"
      >
        <esg-simple-box
          fxFlex="50%"
          fxFlex.md
          fxFlex.lt-sm
          *ngFor="let gap of topN(vm.lever.gaps, 2)"
          [title]="globalRiskMetricKey + gap.key + '.NAME' | translate"
          [description]="globalRiskMetricKey + gap.key + '.DESCRIPTION' | translate"
        >
        </esg-simple-box>
      </div>
    </esg-numbered-box>

    <div fxFlex="30px"></div>

    <esg-numbered-box
      *ngIf="vm.lever.positiveImpacts.length > 0"
      number="2"
      [title]="
        actionLeversKey + 'POSITIVE_IMPACT.HEADING' | translate : { Lever: globalActionLeversKey + vm.lever.key + '.NAME' | translate }">
      <div fxLayout="column" fxLayoutGap="25px">
        <p>
          {{ actionLeversKey + "POSITIVE_IMPACT.PARAGRAPH" | translate }}
        </p>
        <esg-score-and-benchmark
          fxFlex
          [lever]="vm.lever"
          [scoreHeading]="actionLeversKey + 'POSITIVE_IMPACT.SCORE_HEADING' | translate"
          [benchmarkHeading]="actionLeversKey + 'POSITIVE_IMPACT.BENCHMARK_HEADING' | translate"
          barBackgroundColor="#ECEDEE"
          barColor="#003BF7"
          barPercentLabelColor="#fff"
          barOffsetPercentLabelColor="#000"
        ></esg-score-and-benchmark>
      </div>
    </esg-numbered-box>

    <div fxFlex="30px"></div>

    <esg-numbered-box
      [number]="vm.lever.positiveImpacts.length > 0 ? '3' : '2'"
      [title]="actionLeversKey + 'STRENGTHS' | translate : { Lever: globalActionLeversKey + vm.lever.key + '.NAME' | translate }"
    >
      <div
        fxLayout="row"
        fxLayoutGap="20px"
        fxLayout.md="column"
        fxLayout.sm="row"
        fxLayout.lt-sm="column"
      >
        <esg-simple-box
          fxFlex="50%"
          fxFlex.md
          fxFlex.lt-sm
          *ngFor="let strength of topN(vm.lever.strengths, 2)"
          [title]="globalRiskMetricKey + strength.key + '.NAME' | translate" [description]="globalRiskMetricKey + strength.key + '.DESCRIPTION' | translate">
        </esg-simple-box>
      </div>
    </esg-numbered-box>
    <div fxFlex="40px"></div>
  </div>
</ng-container>
