<div fxLayout="column" class="recommendation">
  <div *ngIf="showHint" class='score-hint-overlay'>
    <div
        class="close pull-right"
        aria-label="Close"
        (click)="onShowHint()"
      >      <span aria-hidden="true">×</span>
  </div>

    <div class="hint-suggested-title">
      {{ "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY_DETAIL.RECOMMENDATIONS.HINTS.SCORE_HINT_TITLE_" + recommendation.tier | translate }}
    </div>
    <p class="hint-description">
      {{ "CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS." + recommendation.metricTypeKey + ".REASONS_FOR_SCORE" | translate }}
  </div>
  <div *ngIf="showHint" class='score-hint-transparency-overlay'></div>

  <div fxLayout="row" fxLayoutAlign="space-between end" class="hint-messages">
    <div class="number" fxLayout="row" fxLayoutAlign="center center">
      {{ recommendation.rank }}
    </div>
    <div class="industry-box" *ngIf="recommendation.isIndustryDefault" fxLayout="row" fxLayoutGap="10px">
      <div class="industrytext">
        {{
          "CURRENT_MODEL.GLOBAL.DEFAULT_INDUSTRY_MESSAGES.USING_INDUSTRY_DEFAULT_DATA_MESSAGE" | translate
        }}
      </div>
    </div>

    <div
      class="tier-box"
      fxLayout="row"
      fxLayoutGap="10px"
    >
      <img [src]="'/assets/tiers/tier-' + recommendation.tier.toString() + '.svg'" />
      <div>
        {{ "CURRENT_MODEL.GLOBAL.TIERS." + recommendation.tier.toString() | translate }}
      </div>
      <div>
        <span (click)="onShowHint()" role="button" data-toggle="tooltip" class="float-right icon-help question-icon"></span>
      </div>
    </div>
  </div>

  <div class="name">
    {{ "CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS." + recommendation.metricTypeKey + ".NAME" | translate }}
  </div>

  <div [ngClass]="{ 'description-wrapper' : !showMore, 'description-wrapper-fullheight' : showMore }" fxLayout="row" fxLayoutAlign="space-around">
    <p class="description">
      {{ showMore ? ("CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS." + recommendation.metricTypeKey + ".DESCRIPTION" | translate) : ("CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS." + recommendation.metricTypeKey + ".DESCRIPTION" | translate | slice: 0:maxInitialLength) }}
      <span *ngIf='!showMore && ("CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS." + recommendation.metricTypeKey + ".DESCRIPTION" | translate).length > maxInitialLength'>...
        <a (click)="onShow()" fxLayout="row">
          <div class="icon-circled-chevron-right down"></div>
          <div class="moreorless">More</div>
        </a>
      </span>
      <a *ngIf="showMore" (click)="onShow()" fxLayout="row">
        <div class="icon-circled-chevron-right up"></div>
        <div class="moreorless">Less</div>
      </a>
    </p>

  </div>

  <!-- Single Score Charts -->
  <div *ngIf='("CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS." + recommendation.metricTypeKey + ".CHART_TYPE" | translate) === "SINGLE_SCORE"'>
    <div *ngIf="!showMore" class="circles" fxLayout="row" fxLayoutAlign="space-around">
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="circle-title">{{ companyScoreTitle }}</div>
        <esg-circle-chart [ngClass]="{'chart-score-hint-transparency-overlay': showHint}"
        [data]="[
          {
            values: EsgScoringDataUtilities.mapToRecommendationScore(recommendation.companyScore),
            colour: companyPrimaryColor,
            secondaryColor: companySecondaryColor
          }
        ]"
        [options]="{
          primaryText: recommendation.companyScore.toString(),
          secondaryText: circleInnerText
        }"
      ></esg-circle-chart>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center">
        <div class="circle-title">{{ industryScoreTitle }}</div>
        <esg-circle-chart [ngClass]="{'chart-score-hint-transparency-overlay': showHint}"
        [data]="[
          {
            values: EsgScoringDataUtilities.mapToRecommendationScore(recommendation.industryScore),
            colour: industryPrimaryColor,
            secondaryColor: industrySecondaryColor
          }
        ]"
        [options]="{
          primaryText: recommendation.industryScore.toString(),
          secondaryText: circleInnerText
        }"
      ></esg-circle-chart>
      </div>
    </div>
  </div>
  <!--End Single Score Charts-->

  <!--Start Circle Chart Percentage Chart-->
  <div *ngIf='("CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS." + recommendation.metricTypeKey + ".CHART_TYPE" | translate) === "DUAL_PERCENTAGE"'>
      <div *ngIf="!showMore" class="circles" fxLayout="row" fxLayoutAlign="space-around">
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="circle-title">{{ companyScoreTitle }}</div>

          <esg-circle-chart-percentages [ngClass]="{'chart-score-hint-transparency-overlay': showHint}"
              [data]="[
              {
                values: recommendation.companyScores,
                colour: '#F6517F',
                secondaryColor: '#48086F',
                label1: recommendation.companyScores[0].label ?? ('CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS.' + recommendation.metricTypeKey + '.CHART_LABELS.1' | translate | keyvalue)[0].value.toString(),
                label2: recommendation.companyScores[1].label ?? ('CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS.' + recommendation.metricTypeKey + '.CHART_LABELS.0' | translate | keyvalue)[0].value.toString()
              }
            ]"
            [options]="{
              secondaryText: circleInnerText
            }"
          ></esg-circle-chart-percentages>
        </div>
        <div fxLayout="column" fxLayoutAlign="start center">
          <div class="circle-title">{{ industryScoreTitle }}</div>
          <esg-circle-chart-percentages [ngClass]="{'chart-score-hint-transparency-overlay': showHint}"
            [data]="[
              {
                values: recommendation.industryScores,
                colour: '#F6517F',
                secondaryColor: '#48086F',
                label1: recommendation.industryScores[0].label ?? ('CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS.' + recommendation.metricTypeKey + '.CHART_LABELS.1' | translate | keyvalue)[0].value.toString(),
                label2: recommendation.industryScores[1].label ?? ('CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS.' + recommendation.metricTypeKey + '.CHART_LABELS.0' | translate | keyvalue)[0].value.toString()
              }
            ]"
            [options]="{
              secondaryText: circleInnerText
            }"
          ></esg-circle-chart-percentages>
        </div>
      </div>
    </div>
  <!--End Circle Chart Percentage Chart-->

  <!--Start Range Chart-->
  <div *ngIf='("CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS." + recommendation.metricTypeKey + ".CHART_TYPE" | translate) === "RANGE"'>
    <div *ngIf="!showMore" class="circles" fxLayout="row" fxLayoutAlign="center end">
        <esg-range-chart 
        [ngClass]="{'chart-score-hint-transparency-overlay': showHint}"
        [rangeChartOptions]="{
          scores: [recommendation.industryScores, recommendation.companyScores],
          labels: [industryScoreTitle, companyScoreTitle],
          seriesColors: [industryPrimaryColor, companyPrimaryColor],
          minXAxis: 20,
          maxXAxis: 100
        }">
        </esg-range-chart>
    </div>
  </div>
  <!--End Range Chart-->

  <div *ngIf="!showMore"
    class="poweredby"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="5px"
  >
    <div>
      {{
        "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY_DETAIL.RECOMMENDATIONS.POWERED_BY"
          | translate
      }}
    </div>
    <img src="/assets/logos/{{ recommendation.poweredBy }}.png" />
  </div>

  <ul fxLayout="column">
    <li *ngFor="let lever of recommendation.action_lever">
      <a
        (click)="clickActionLever(this.lever)"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="action-lever-text">
          {{
            "CURRENT_MODEL.GLOBAL.ACTION_LEVERS." +
              lever.trim().toUpperCase() +
              ".NAME" | translate
          }}
        </div>
        <div class="icon-back-arrow-2 reverse"></div>
      </a>
    </li>
  </ul>
  
</div>
