import { HttpResponse } from "@angular/common/http";
import { ProxyHttp } from "@wtw/platform/api";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiResult } from "@wtw/platform/api";
import { map, shareReplay } from "rxjs/operators";
import { ScreenerSettings } from "./generated/ScreenerSettings";
import { ExistingScreener } from "./generated/ExistingScreener";
import { ScreenerCount, ScreenerListItem } from "./generated";

@Injectable({
  providedIn: "root",
})
export class ScreenersProxy {
  
  constructor(private _phttp: ProxyHttp) {}

  searchScreenerSettings(
    screenerSettings: ScreenerSettings
  ): Observable<ApiResult<ExistingScreener>> {
    return this._phttp.post(false, `/screeners/searchbysettings/client/${screenerSettings.clientId}`, screenerSettings || {})
      .pipe(
        map(
          (ret: HttpResponse<ExistingScreener>) =>
            <ApiResult<ExistingScreener>>{
              data: ret.body,
              originalResponse: ret,
            }
        )
      );
  }

  updateActiveScreener(
    runId: number,
    clientId: number
  ): Observable<ApiResult<boolean>> {
    return this._phttp.post(false, `/screeners/setasactive/run/${runId}/client/${clientId}`, null || {})
      .pipe(
        map(
          (ret: HttpResponse<boolean>) => <ApiResult<boolean>>{ data: ret.body, originalResponse: ret }
        )
      );
  }

  createScreener(
    screenerSettings: ScreenerSettings
  ): Observable<ApiResult<number>> {
    return this._phttp
      .post(false, `/screeners/create`, screenerSettings || {})
      .pipe(
        map(
          (ret: HttpResponse<number>) =>
            <ApiResult<number>>{ data: ret.body, originalResponse: ret }
        )
      );
  }

  // Make a request via WebApi to the orchestration function to add a dequeuer job
  requestNewScreenerCreateDataJob(
    clientId: number,
    screenerId: number
  ): Observable<ApiResult<number>> {
    return this._phttp.post(false, `/screeners/requestdatacalculationjob/client/${clientId}/screenerid/${screenerId}`, {})
      .pipe(
        map((ret: HttpResponse<number>) =>
            <ApiResult<number>>{ data: ret.body, originalResponse: ret }
        )
      );
  }

  // Check the ESG dequeuer job store for a completed jobkey
  // we are expecting a success/falure result
  screenerDataGenerationStatus(jobKey: number): Observable<ApiResult<string>> {
    return this._phttp
      .get(false, `/screeners/screenerdatagenerationjobstatus/key/${jobKey}`, {})
      .pipe(
        map(
          (ret: HttpResponse<string>) => <ApiResult<string>>{ data: ret.body, originalResponse: ret }
        )
      );
  }

  screeners(clientId: number): Observable<ApiResult<ScreenerListItem[]>> {
    return this._phttp.get(false, `/screeners/client/${clientId}`)
      .pipe(
        map((ret: HttpResponse<ScreenerListItem[]>) => {
          return <ApiResult<ScreenerListItem[]>> {
            data: ret.body,
            originalResponse: ret,
          };
        })
    );
  }

  screener(runId: number): Observable<ApiResult<ScreenerListItem>> {
    return this._phttp.get(false, `/screeners/run/${runId}`).pipe(
      shareReplay(1),
      map((ret: HttpResponse<ScreenerListItem>) => {
        return <ApiResult<ScreenerListItem>>{
          data: ret.body,
          originalResponse: ret,
        };
      })
    );
  }

  delete(screenerId: number): Observable<ApiResult<void>> {
    return this._phttp
      .delete(false, `/screeners/delete/${screenerId}`, {
        observe: "response",
      })
      .pipe(
        map((ret: HttpResponse<void>) => {
          return <ApiResult<void>>{
            data: ret.body,
            originalResponse: ret,
          };
        })
      );
  }

  // Call the edit endpoint to update name and description
  edit(
    screenerSettings: ScreenerSettings,
    screenerId: number
  ): Observable<ApiResult<number>> {
    return this._phttp
      .post(false, `/screeners/edit/${screenerId}`, screenerSettings || {})
      .pipe(
        map(
          (ret: HttpResponse<number>) =>
            <ApiResult<number>>{ data: ret.body, originalResponse: ret }
        )
      );
  }

  getDefaultScreenerRunId(clientId: number) {
    return this._phttp.get(false, `/screeners/client/${clientId}/default`).pipe(
      map((ret: HttpResponse<number>) => {
        return <ApiResult<number>>{
          data: ret.body,
          originalResponse: ret,
        };
      })
    );
  }

  screenerCount(clientId) {
    return this._phttp.get(false, `/screeners/client/${clientId}/count`)
      .pipe(
        map((ret: HttpResponse<ScreenerCount>) => {
          return <ApiResult<ScreenerCount>>{
            data: ret.body,
            originalResponse: ret,
          };
        })
      );
  }

  isUsingIndustryDefaultData(clientId) {
    return this._phttp.get(false, `/screeners/client/${clientId}/isusingindustrydefaultdata`)
      .pipe(
        map((ret: HttpResponse<boolean>) => {
          return <ApiResult<boolean>>{
            data: ret.body,
            originalResponse: ret,
          };
        })
      );
  }  
}
