<ng-container *ngIf="viewModel$ | async as vm">
  <div fxLayout="row" ngClass.xs="extra-small">
    <a
      *ngIf="vm.previous"
      [routerLink]="'../' + vm.previous.path"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <div class="icon-back-arrow-2"></div>
      <div>
        {{
          "CURRENT_MODEL.RUN_PAGES." + vm.previous.path.toUpperCase()
            | translate
        }}
      </div>
    </a>
    <div fxFlex></div>
    <a
      *ngIf="vm.next"
      [routerLink]="'../' + vm.next.path"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="8px"
    >
      <div style="text-align: right">
        {{
          "CURRENT_MODEL.RUN_PAGES." + vm.next.path.toUpperCase() | translate
        }}
      </div>
      <div class="icon-back-arrow-2 reverse"></div
    ></a>
  </div>

  <h2 *ngIf="showCurrent">
    {{ "CURRENT_MODEL.RUN_PAGES." + vm.current.path.toUpperCase() | translate }}
  </h2>
</ng-container>
