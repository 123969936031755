import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { IModalConfirmation } from "@wtw/platform/interfaces";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject, filter, Subscription } from "rxjs";
import { EditScreenerModalData } from "./edit-screener-modal-data";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
@Component({
  selector: "esg-edit-screener-modal",
  templateUrl: "./edit-screener-modal.component.html",
  styleUrls: ["./edit-screener-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditScreenerModalComponent implements IModalConfirmation, OnDestroy, OnInit {
  subscriptions = new Subscription;
  data: EditScreenerModalData;
  originalData: EditScreenerModalData;
  onClose = new Subject<boolean>();
  form: FormGroup;
  screenerName = new FormControl("", Validators.required)
  screenerDescription = new FormControl("", Validators.required)
  checkBoxSetAsActive = new FormControl(false)
  
  constructor(
    private bsModalRef: BsModalRef,
    formBuilder: FormBuilder
  ) {

    this.form = formBuilder.group({
      "screenerName": this.screenerName,
      "screenerDescription": this.screenerDescription,
      "checkBoxSetAsActive": this.checkBoxSetAsActive
    });

    this.subscriptions.add(this.form.valueChanges
      .pipe(
        filter((_) => this.form.valid)
      ).subscribe((formData) => {
        // If it's is an edit as opposed to a new screener 
        // ensure that the form values are different otherwise don't allow submission
        if(this.originalData.screenerName === formData.screenerName && 
          this.originalData.screenerDescription === formData.screenerDescription) {
            this.form.setErrors({ 'invalid': true });
        }
        else {
          this.data.screenerName = formData.screenerName;
          this.data.screenerDescription = formData.screenerDescription;
          this.data.setScreenerAsActiveForUser = formData.checkBoxSetAsActive;
        }
      })
    );
  }

  ngOnInit(): void {
    this.originalData = { ...this.data };
    this.screenerName.patchValue(this.data.screenerName);
    this.screenerDescription.patchValue(this.data.screenerDescription);
  }

  show(): void {}

  close(createRun: boolean) {
    this.onClose.next(createRun);
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
