<div fxLayout="column">  
  <div class="screener-description" fxFlex fxLayoutAlign="start center">
    {{ screener?.screenerDescription }}
  </div>

  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
    <button routerLink="../screener-management" class="btn btn-primary">
      {{ executiveSummaryKey + ".SCREENER_MANAGEMENT" | translate }}
    </button>
    <div fxFlex.gt-xs></div>
    <button
      [hidden]="screener?.isActiveForMe"
      (click)="setAsActiveScreener(screener)"
      class="btn btn-primary"
    >
      {{ executiveSummaryKey + ".SET_AS_ACTIVE_SCREENER" | translate }}
    </button>
  </div>
  <div
    class="historical-runs"
    fxLayoutAlign="end center"
    fxLayout="row"
    fxLayoutGap="10px"
  >
    <b>{{ executiveSummaryKey + ".AS_OF_DATE" | translate }}</b>

    <select [ngModel]="screener?.runId" (ngModelChange)="changeAsOf($event)">
      <option
        [value]="scoringRun.runId"
        *ngFor="let scoringRun of screener?.historicalScoringRecords"
      >
        {{ scoringRun.createDateUTC | date }}
      </option>
    </select>
  </div>
</div>
