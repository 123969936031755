import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlatformRootComponent } from '@wtw/platform';
import { CommonModule } from './common.module';

export declare let require: any;

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule
    ],
    bootstrap: [PlatformRootComponent],
    declarations: []
})
export class AppModule {}
