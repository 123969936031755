import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RunService } from "@wtw/platform/services";
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import { combineLatest, map } from "rxjs";
more(Highcharts);

@Component({
  selector: "esg-companies-comparison-chart",
  templateUrl: "./companies-comparison-chart.component.html",
  styleUrls: ["./companies-comparison-chart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompaniesComparisonChartComponent {
  constructor(
    private runService: RunService,
    private activatedRoute: ActivatedRoute) {}

  translationKey = "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY_DETAIL.THEMES.COMPANIES-COMPARISON";

  viewModel$ = combineLatest([
    this.runService.activeRun,
    this.activatedRoute.parent.url
  ]).pipe(
    map(([activeRun, url]) => {
      const pillar = activeRun.data.esgPillars.find(
        (s) => s.key.toLowerCase() == url[0].path.toLocaleLowerCase()
      );
      
      const screenerCompanyPercentile = activeRun.data.esgPillars.find(p => p.key === pillar.key).percentile;
      const comparisonCompanyScores: {name: string, percentile: number}[] = [];

      comparisonCompanyScores.push({name: activeRun.data.benchmarkingSelections[0].clientName, 
        percentile: screenerCompanyPercentile});

      activeRun.data.comparisonCompanies
        .forEach(company => {
          const companyName = company.name;
          const percentile = company.esgPillars.find(p => p.key === pillar.key).percentile;
          comparisonCompanyScores.push({name: companyName, percentile: percentile});
        });
        const boxChartSeriesData = this.BoxChartData();
        const boxChartPercentileLabels = this.BoxChartPercentileLabels();
        const scatterChartCompanyLabels = this.CompanyDataScatterSeriesPoints(comparisonCompanyScores);
        const chartOptions = this.buildChartOptions(boxChartSeriesData, boxChartPercentileLabels, scatterChartCompanyLabels);
      return {
        chartOptions,
        highcharts: Highcharts,
      };
    })
  );

  private buildChartOptions(seriesData: any, boxChartPercentileLabels: Highcharts.SeriesOptionsType, scatterChartCompanyLabels: Highcharts.SeriesOptionsType[]) : Highcharts.Options {
    return {
      chart: {
        type:"boxplot",
        inverted: true,
        height:250,
        marginLeft: 440,
        backgroundColor: '#F5F5F5',
      },
      title: {
        text: ''
      },
      tooltip:{
        enabled: false,
      },
      plotOptions: {
        boxplot: {
          medianWidth:0,
          whiskerWidth: 1,          
        },
        series: {
          color: '#333',
        }
     },
      legend: {
        align: 'left',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 50,
        itemMarginBottom: 10,
        itemMarginRight: 100,
        labelFormatter: function () {
          const seriesOptions = this as any;
          return seriesOptions.name + '     (' + seriesOptions.yData[0] + ')';
      }
      },
      xAxis: [{
        visible: false,
      },
      {
        visible: false,
        min: -50,
        max: 50
      },
    ],

      yAxis: [{
        visible: false,
    }],
      series: [
        {
          enableMouseTracking: false,
          showInLegend: false,
          data: seriesData
        },
        boxChartPercentileLabels,
        ...scatterChartCompanyLabels
      ],
      credits: {
        enabled: false,
      },
    } as Highcharts.Options;
  }

  private BoxChartData() : Highcharts.PointOptionsObject[] {
    const data = [
      { low: 5, q1: 25, median: 50, q3: 75, high: 95 }
    ] as Highcharts.PointOptionsObject[];
    return data;
  }

  private BoxChartPercentileLabels() : Highcharts.SeriesOptionsType {
    const data = [
      { x: 0, y: 5, name: '5th Percentile', dataLabels: { x: 0, y: 80 }}, 
      { x: 0, y: 25, name: '25th Percentile', dataLabels: { x: 0, y: 120 }}, 
      { x: 0, y: 50, name: '50th Percentile', dataLabels: { x: 0, y: 120 }}, 
      { x: 0, y: 75, name: '75th Percentile', dataLabels: { x: 0, y: 120 }}, 
      { x: 0, y: 95, name: '95th Percentile', dataLabels: { x: 0, y: 80 }}, 
    ] as Highcharts.PointOptionsObject[];

    return {
      type: 'scatter',
      showInLegend: false,
      enableMouseTracking: false,
      dataLabels: {
        format: '{key}',
        enabled: true,
        style: {
          textOutline: 'none',
          color: '#000'
        }
      },
      data: data,
      marker: {
        enabled: false,
      },
    } as Highcharts.SeriesOptionsType;
  }

  private CompanyDataScatterSeriesPoints(companyScore: {name:string, percentile:number}[]) : Highcharts.SeriesOptionsType[] {
    const seriesOptions = [] as Highcharts.SeriesOptionsType[];
    const data = [];
    companyScore.map(company => {
      
        if(data.find(d => d.y === company.percentile))
        {
          var ranNum = Math.ceil(Math.random() * 25) * (Math.round(Math.random()) ? 1 : -1)
          data.push({ x: ranNum, y: company.percentile, dataLabels: { x:0, y:80 }, companyName:company.name } as Highcharts.PointOptionsObject)
        }
        else {
          data.push({ x: 0, y: company.percentile, dataLabels: { x: 0, y: 80 }, companyName:company.name } as Highcharts.PointOptionsObject)
        }
    });

    // loop through this array, check each value is
    const colours = ['#C900AC', '#3ADCC9', '#F6517F', '#FF8204', '#327FEF']

    data.map(d => {
      const innerData = [] as Highcharts.PointOptionsObject[];
      innerData.push(d);
      seriesOptions.push({
        type: 'scatter',
        name: d.companyName,
        enableMouseTracking: false,
        dataLabels: {
          enabled: false,
        },
        
        data: innerData,
        marker: {
          enabled: true,
          radius: 10,
          fillColor: colours[data.indexOf(d)]
        },
        xAxis: 1
      } as Highcharts.SeriesOptionsType)
    })
    
    return seriesOptions
  }
}

