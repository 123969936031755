import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "esg-simple-box",
  templateUrl: "./simple-box.component.html",
  styleUrls: ["./simple-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleBoxComponent {
  @Input() title: string;
  @Input() description: string;
}
