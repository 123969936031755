<div>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{data.heading}}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="close(false)"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="form-group">
        <label for="screenerName">
          {{
            "CURRENT_MODEL.PAGES.CUSTOMIZE.CREATE_NEW_SCREENER_MODAL.FORM_LABEL_SCREENER_NAME" 
            | translate
          }}
        </label>
        <input type="text" 
          class="form-control" 
          formControlName="screenerName" 
          placeholder='{{"CURRENT_MODEL.PAGES.CUSTOMIZE.CREATE_NEW_SCREENER_MODAL.FORM_LABEL_SCREENER_NAME" | translate}}'>
      </div>

      <div class="form-group">
        <label for="screenerDescription">
          {{
            "CURRENT_MODEL.PAGES.CUSTOMIZE.CREATE_NEW_SCREENER_MODAL.FORM_LABEL_SCREENER_DESCRIPTION" 
            | translate
          }}
        </label>
        <textarea 
          class="form-control" 
          formControlName="screenerDescription"
          placeholder='{{"CURRENT_MODEL.PAGES.CUSTOMIZE.CREATE_NEW_SCREENER_MODAL.FORM_LABEL_SCREENER_DESCRIPTION" | translate}}'>
        </textarea>
        <div class="show-industrydata-message" *ngIf="data.showIndustrydataMessage">
          <label class="sub-header">Note:</label>
          {{
            "CURRENT_MODEL.GLOBAL.DEFAULT_INDUSTRY_MESSAGES.SHOW_INDUSTRYDATA_MESSAGE" 
            | translate
          }}
        </div>
        <mat-checkbox 
          *ngIf="data.showSetAsActiveOption"
          class="checkbox-setas-active"
          formControlName="checkBoxSetAsActive"
          >
          {{
            "CURRENT_MODEL.PAGES.CUSTOMIZE.CREATE_NEW_SCREENER_MODAL.FORM_LABEL_SETASACTIVE" 
            | translate
          }}
        </mat-checkbox>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary" [disabled]="!form.valid || !form.dirty" (click)="close(true)"
    >
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <div>{{data.acceptButtonText}}</div>
      </div>
    </button>
    <button type="button" class="btn btn-default" (click)="close(false)">
      {{data.cancelButtonText}}
    </button>
  </div>
</div>
