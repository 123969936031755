import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { MediaObserver } from "@angular/flex-layout";
import { map } from "rxjs";
import { ActionLever } from "src/app/api/generated";
@Component({
  selector: "esg-score-and-benchmark",
  templateUrl: "./score-and-benchmark.component.html",
  styleUrls: ["./score-and-benchmark.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScoreAndBenchmarkComponent {
  constructor(private mediaObserver: MediaObserver) {}
  globalLOBKey = "CURRENT_MODEL.GLOBAL.LINES_OF_BUSINESS.";

  @Input() lever: ActionLever;
  @Input() scoreHeading: string;
  @Input() benchmarkHeading: string;
  @Input() barBackgroundColor: string;
  @Input() barColor: string;
  @Input() barPercentLabelColor: string;
  @Input() barOffsetPercentLabelColor: string;

  labelWidthPixels = 200;
  barHeightPixels = 45;

  labelWidthPixelsSmall = 110;
  barHeightPixelsSmall = 65;

  viewModel$ = this.mediaObserver.asObservable().pipe(
    map((state) => {
      return {
        lessThanLarge: state.some((s) => s.mqAlias === "lt-lg"),
        extraSmall: state.some((s) => s.mqAlias === "xs"),
      };
    })
  );
}
