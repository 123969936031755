import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import * as moment from "moment";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { Score } from "src/app/api/generated";
import { EsgScoringDataUtilities } from "src/app/shared/helpers/esg-scoring-data-utilities";
more(Highcharts);

export class PerformanceBenchmarkOptions {
  xAxisTitle: string;
  yAxisTitle: string;
  bubbleColour: string;
  latestScoreBubbleColour: string;
  bubbleValueColour: string;
}

@Component({
  selector: "esg-performance-benchmark",
  templateUrl: "./performance-benchmark.component.html",
  styleUrls: ["./performance-benchmark.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformanceBenchmarkComponent {
  private options$ = new BehaviorSubject<PerformanceBenchmarkOptions>(
    {} as PerformanceBenchmarkOptions
  );

  private performanceScores$ = new BehaviorSubject<Score[]>([]);

  @Input() set options(options: PerformanceBenchmarkOptions) {
    this.options$.next(options);
  }

  @Input() set performanceScores(scores: Score[]) {
    const scoresByAscendingDate = EsgScoringDataUtilities.sortPillarPerformanceScoresByDate(scores, 'asc');
    this.performanceScores$.next(scoresByAscendingDate);
  }

  viewModel$ = combineLatest([this.options$, this.performanceScores$]).pipe(
    map(([options, scores]) => {
      const xAxisLabels = scores.map((score) =>
        moment(score.date).format("MM-YYYY")
      );

      const seriesData = this.buildSeriesData({
        scores,
        bubbleColour: options.bubbleColour,
        latestScoreBubbleColour: options.latestScoreBubbleColour,
      });

      const chartOptions = this.buildChartOptions({
        seriesData,
        bubbleValueColour: options.bubbleValueColour,
        xAxisTitle: options.xAxisTitle,
        yAxisTitle: options.yAxisTitle,
        xAxisLabels,
      });

      return {
        chartOptions,
        highcharts: Highcharts,
      };
    })
  );

  private buildChartOptions(config: {
    seriesData: Highcharts.PointOptionsObject[];
    bubbleValueColour: string;
    xAxisTitle: string;
    yAxisTitle: string;
    xAxisLabels: string[];
  }): Highcharts.Options {
    return {
      series: [
        {
          enableMouseTracking: false,
          name: config.yAxisTitle,
          data: config.seriesData,
          type: "bubble",
          dataLabels: {
            enabled: true,
            format: "{point.y}",
            shadow: false,
            style: {
              color: config.bubbleValueColour,
              textOutline: "0px contrast",
            },
          },
          marker: {
            fillOpacity: 1,
          },
        },
      ],
      credits: {
        enabled: false,
      },
      chart: {
        plotBorderWidth: 1,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: config.xAxisLabels,
        max: config.xAxisLabels.length - 1,
        tickWidth: 1,
        title: {
          text: config.xAxisTitle,
        },
      },
      yAxis: {
        //min: 0,
        //max: 100,
        tickInterval: 10,
        title: {
          text: config.yAxisTitle,
        },
      },
    } as Highcharts.Options;
  }

  private buildSeriesData(config: {
    scores: Score[];
    bubbleColour: string;
    latestScoreBubbleColour: string;
  }): Highcharts.PointOptionsObject[] {
    
    const latestScoreDate = config.scores[config.scores.length - 1].date;

    return config.scores.map(
      (score, index) =>
        ({
          x: index,
          y: score.percentile,
          marker: {
            fillColor:
              score.date === latestScoreDate
                ? config.latestScoreBubbleColour
                : config.bubbleColour,
            lineWidth: score.date === latestScoreDate ? 4 : 0,
            lineColor: config.bubbleColour,
          },
        } as Highcharts.PointOptionsObject)
    );
  }
}
