<div class="individualcompanydetails">
    <h2>{{ individualCompanyDetailsTranslationKey + ".HEADING" | translate }}</h2>
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.gt-sm="40px" fxLayoutGap.sm="10px" fxLayoutGap.lt-sm="10">
        <div class="left" fxFlex="35" fxFlex.sm="100">
            <p class="company-search-description">{{ individualCompanyDetailsTranslationKey + ".DESCRIPTION" | translate }} ({{ individualCompanyDetailsTranslationKey + ".MAX_COMPANY_COUNT" | translate }})</p>
            <div class="search-controls">
                <autocomplete_clientSearch id="client-search"  class="item-underline" [dataSource]="search"
                    (focus)="onFocus()"
                    (enter)="companySelected($event)"
                    (statusChange)="statuschanged($event)"
                    [notFoundMessage]="'HOMEPAGE.NOT_FOUND_MESSAGE' | translate">
                    <ng-template let-item="item">
                        <div class="client-search-result">
                            {{ item.name}}
                        </div>
                    </ng-template>
                </autocomplete_clientSearch>
            </div>
        </div>
        <div *ngIf="$comparisonCompanies | async as comparisonCompanies" fxFlex="65" fxFlex.sm="100">
            <div class="companies-list" *ngIf="comparisonCompanies.companies.length">
                <ul>
                    <li *ngFor="let company of comparisonCompanies.companies; let i = index" 
                        fxLayout="row"
                    >
                        <div fxFlex="60" class="company-name">{{company.name}}</div>
                        <div fxFlex="30" fxFlex class="using-default-data">Data exists</div>
                        <div fxFlex="5">
                            <div [ngClass]="{
                                'icon-tick':company.isUsingIndustryDefaultData===false,
                                'using-industry-default-data':company.isUsingIndustryDefaultData===true
                            }"></div>
                        </div>
                        <div fxFlex="5" class="icon-close_thin remove-company" (click)="removePeerCompany(i)"></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
