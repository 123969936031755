import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { MetricTypeItem } from "src/app/api/generated";

@Component({
  selector: "esg-metric-items",
  templateUrl: "./metric-items.component.html",
  styleUrls: ["./metric-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetricItemsComponent {
  @Input() items: MetricTypeItem[];
  @Output() itemSelected = new EventEmitter<MetricTypeItem>();
}
