<div *ngIf="viewModel$ | async as vm" class="financials">
  <h2>{{ financialsTranslateKey + ".HEADING" | translate }}
  </h2>
  
  <p>
    {{ financialsTranslateKey + ".DESCRIPTION" | translate }}
  </p>
  <div
    fxLayout="row"
    fxLayout.lt-sm="column"
    fxLayoutGap.gt-sm="60px"
    fxLayoutGap.lt-sm="20px"
    fxLayoutAlign="start"
    fxLayoutAlign.sm="space-between"
  >
    <div fxFlex.gt-xs="200px">
      <div class="dropdown-heading">
        {{ financialsTranslateKey + ".INDUSTRY_HEADING" | translate }}
      </div>
      <select
        [ngClass]="{'select-wrapper': true, 'disabled': isUser}"
        [(ngModel)]="benchmarkingSelection.industryPICS6"
        (ngModelChange)="change()"
      >
        <option
          [ngValue]="industry.picS6"
          *ngFor="let industry of vm.industries"
        >
          {{ industry.description }}
        </option>
      </select>
    </div>
    <div fxFlex.gt-xs="200px">
      <div class="dropdown-heading">
        {{ financialsTranslateKey + ".EMPLOYEE_HEADING" | translate }}
      </div>
      <select
        [ngClass]="{'select-wrapper': true, 'disabled': isUser}"
        [(ngModel)]="benchmarkingSelection.employeeBucketId"
        (ngModelChange)="change()"
      >
        <option
          [ngValue]="employeeBucket.id"
          *ngFor="let employeeBucket of vm.employeeBuckets"
        >
          {{ employeeBucket.description }}
        </option>
      </select>
    </div>
    <div fxFlex.gt-xs="200px">
      <div class="dropdown-heading">
        {{ financialsTranslateKey + ".REVENUE_HEADING" | translate }}
      </div>
      <select
        [ngClass]="{'select-wrapper': true, 'disabled': isUser}"
        [(ngModel)]="benchmarkingSelection.revenueBucketId"
        (ngModelChange)="change()"
      >
        <option
          [ngValue]="revenueBucket.id"
          *ngFor="let revenueBucket of vm.revenueBuckets"
        >
          {{ revenueBucket.description }}
        </option>
      </select>
    </div>
    <div class="company-details" fxLayout="column" fxFlex fxLayoutAlign="center center">
      <label class="circle" fxLayoutAlign="center center"> {{ peerCompanyCount }} </label>
      <span>        
        {{ financialsTranslateKey + ".NUMBEROFCOMPANIES_DESC" | translate }} 
      </span>
    </div>    
  </div>
</div>
