<ul fxLayout="column" fxLayout.sm="row wrap">
  <div class="link">    
    <li fxFlex.sm="50%">
      <div *ngFor="let pillar of pillars">
        <a
          [routerLink]="['../offerings']"
          [queryParams]="{ filter: 'IS_' + pillar.key}"
          fxLayout="row"
        >
          <div>
             {{ "CURRENT_MODEL.GLOBAL.ESG_PILLARS." + pillar.key + ".NAME" | translate }}
          </div>
        </a>
        <div class="toptheme" fxlayout="row" fxlayoutalign="end center" fxLayoutGap="5px">
          <label>{{ "CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY_DETAIL.THEMES." + getTopTheme(pillar.key) + ".NAME" | translate  }}</label>      
          <a class="icon-back-arrow-2 arrow-medium-blue"
            [routerLink]="['../offerings', getTopLever(pillar.key).toLowerCase()]"
            [queryParams]="{ filter: 'IS_' + pillar.key}"
          >
            </a>
        </div>
      </div>
    </li>
  </div>
</ul>