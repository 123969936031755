import { HighchartsChartModule } from "highcharts-angular";
import { RemoveSpacesPipe } from "./pipes/removeSpacesPipe";
import { NpnSliderModule } from "npn-slider";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MetaReducer } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { PlatformRootModule } from "@wtw/platform";
import { ToolkitModule } from "@wtw/toolkit";

import {
  PLATFORM_URL,
  RUN_LIST_NAVIGATION,
  RUN_PAGES,
} from "@wtw/platform/bootstrap/extension-points";
import { ClickOutsideModule } from "ng-click-outside";
import { FileUploadModule } from "ng2-file-upload";
import { storeFreeze } from "ngrx-store-freeze";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import * as Config from "./config";
import { MainComponents } from "./pages";
import * as Routing from "./routes";
import { SharedComponents } from "./shared";
import { environment } from "./../environments/environment";
import { UrlHelperService } from "./shared/helpers/url-helper.service";
import { SearchFilterPipe } from "./pipes/SearchFilter";
import { NumberFormatterPipe } from "./pipes/number-formatter.pipe";
import { SharedPipes } from "./pipes";
import { SharedComponentsModule } from "./shared/components/shared-components.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ModelNavBarService } from "@wtw/platform/services";
import { ESGModelNavBarService } from "./services/esg-nav-bar.service";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { AppInsightsRunCompleteResolver } from "./services/resolvers/AppInsightsRunCompleteResolver";
// eslint-disable-next-line max-len

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [storeFreeze]
  : [];

@NgModule({
  declarations: [...MainComponents, ...SharedComponents, ...SharedPipes],
  imports: [
    PlatformRootModule.forRoot([
      { provide: RUN_PAGES, useValue: { routes: Routing.RUN_PAGES } },
      {
        provide: RUN_LIST_NAVIGATION,
        useValue: { runLinks: Config.RUN_LIST_NAVIGATION },
      },
      {
        provide: PLATFORM_URL,
        useValue: {
          platformConfig: { platformUrl: UrlHelperService.getPlatformUrl() },
        },
      },
    ]),
    ToolkitModule,
    ReactiveFormsModule,
    FileUploadModule,
    BsDatepickerModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ClickOutsideModule,
    NpnSliderModule,
    SharedComponentsModule,
    FlexLayoutModule,
    MatCheckboxModule,
    MatRadioModule,
    HighchartsChartModule,
  ],
  providers: [
    AppInsightsRunCompleteResolver,
    SearchFilterPipe,
    RemoveSpacesPipe,
    NumberFormatterPipe,
    { provide: ModelNavBarService, useClass: ESGModelNavBarService },
  ],
})
export class CommonModule {}
