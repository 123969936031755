import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "esg-button-tabs",
  templateUrl: "./button-tabs.component.html",
  styleUrls: ["./button-tabs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonTabsComponent {
  @Input() items: ButtonTabItemVM[];
}

export class ButtonTabItemVM {
  text: string;
  route: string;
}
