import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "esg-button-menu",
  templateUrl: "./button-menu.component.html",
  styleUrls: ["./button-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonMenuComponent {
  @Input() items: ButtonMenuItemVM[];

  @Output() itemSelected = new EventEmitter<ButtonMenuItemVM>();
}

export class ButtonMenuItemVM {
  text: string;
  key: string;
  selected: boolean;
}
