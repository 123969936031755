import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "esg-graph-bar",
  templateUrl: "./graph-bar.component.html",
  styleUrls: ["./graph-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphBarComponent implements AfterViewInit {
  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.checkOffsetLabel();
    this.cdr.detectChanges();
  }
  showOffsetLabel: boolean;

  @Input() label: string;
  @Input() labelWidthPixels: number;
  @Input() heightPixels: number;
  @Input() percentageValue: number;
  @Input() percentageLabel: number;
  @Input() backgroundColor: string = "#ddd";
  @Input() barColor: string = "blue";
  @Input() percentLabelColor: string = "white";
  @Input() offsetPercentLabelColor: string = "black";

  @ViewChild("bar") bar: ElementRef;

  @HostListener("window:resize", ["$event"])
  private onResize(event) {
    this.checkOffsetLabel();
  }

  private checkOffsetLabel() {
    this.showOffsetLabel = this.bar.nativeElement.offsetWidth <= 40;
  }
}
