import { AppInsightsEventAction } from 'src/app/shared/enums/app-insights-event-action';
import { AppInsightsEventCategory } from './../../enums/app-insights-event-category';
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IModalConfirmation } from "@wtw/platform/interfaces";
import { UrlHelperService } from "@wtw/platform/services/url-helper.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { EsgAppInsightsService } from "src/app/services/esg-app-insights-service";
import { DownloadInfo } from "./download-info";
import { DownloadModalData } from "./download-modal-data";

@Component({
  selector: "esg-download-modal",
  templateUrl: "./download-modal.component.html",
  styleUrls: ["./download-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadModalComponent implements IModalConfirmation {
  data: DownloadModalData;

  onClose = new Subject<boolean>();

  selectedDownload: DownloadInfo;

  constructor(
    private bsModalRef: BsModalRef,
    private esgAppInsightsService: EsgAppInsightsService
    ) {}

  show(): void {}

  close() {
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

  selectReportClick(download: DownloadInfo) {
    this.selectedDownload = this.selectedDownload === download ? null : download;

  }

  download() {
    const runId = this.data.runId;
    
    const additionalFields = { ReportName: this.selectedDownload.REPORT_IDENTIFIER}
    this.esgAppInsightsService.logInsight(runId, AppInsightsEventCategory.CLIENT_RUNS, AppInsightsEventAction.REPORTDOWNLOADED, additionalFields);
    
    window.location
      .assign(`${UrlHelperService.getPlatformUrl()}/proxy/binary/powerpoint?runId=${runId}&reportType=${this.selectedDownload.REPORT_IDENTIFIER}`);
  }
}
