<esg-app-header
  [backlinkText]="
    'CURRENT_MODEL.PAGES.EXECUTIVE_SUMMARY.PAGE_TITLE' | translate
  "
  backlinkLocation="../executive-summary"
  [pageTitle]="screenerManagementKey + '.PAGE_TITLE' | translate"
  [hideBreadcrumb]="isClient"
></esg-app-header>

<div fxLayout="row">
  <div fxFlex fxFlex.lt-lg="20px"></div>
  <div fxFlex.lt-lg fxFlex="1160px" fxLayout="column" fxLayoutGap="20px">
    <h1 style="text-align: center">{{ (client$ | async)?.displayName }}</h1>

    <mat-radio-group
      [ngModel]="screenerFilterAction$.value"
      (change)="screenerFilterChanged($event)"
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="center center"
      fxLayoutGap="30px"
      fxLayoutGap.lt-sm="10px"
    >
      <mat-radio-button value="ALL">All Screeners</mat-radio-button>
      <mat-radio-button value="MINE">My Screeners</mat-radio-button>
    </mat-radio-group>

    <div fxLayout="column" fxFlex>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        style="padding: 7px 20px; font-weight: bold"
      >
        <div fxFlex>Name</div>
        <div fxFlex="110px" fxHide.lt-md>Date Created</div>
        <div fxFlex="150px" fxHide.lt-sm>Created by</div>
        <div fxFlex="50px">Active</div>
        <div fxFlex="70px" fxHide.lt-sm></div>
      </div>
      <ul
        fxLayout="column"
        class="screener-list"
        *ngIf="viewModel$ | async as vm; else spinner"
      >
        <li
          fxLayout="column"
          *ngFor="let screener of vm.screeners"
          [class.loaded]="screener.runId === vm.currentRunId"
        >
          <div
            class="list-item"
            [class.selected]="selectedScreener === screener"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            (click)="
              selectedScreener === screener
                ? (selectedScreener = null)
                : (selectedScreener = screener)
            "
          >
            <div fxFlex>
              {{ screener.screenerName }}
            </div>
            <div fxFlex="110px" fxHide.lt-md>
              {{ screener.createdDateUTC | date }}
            </div>
            <div fxFlex="150px" fxHide.lt-sm>
              {{ screener.createdByFullName }}
            </div>
            <div fxFlex="50px" fxLayoutAlign="center">
              <div
                class="circle"
                [class.active]="
                  screener.hasActiveUsers || screener.isActiveForMe
                "
              ></div>
            </div>
            <div fxFlex="70px" fxHide.lt-sm>
              <button
                *ngIf="screener.runId !== vm.currentRunId"
                class="btn btn-primary"
                (click)="
                  load(screener);
                  $event.stopPropagation()
                "
              >
                Load
              </button>
            </div>
          </div>
          <div
            class="expander"
            *ngIf="selectedScreener === screener"
            fxLayout="column"
            fxLayoutGap="15px"
          >
            <div fxHide.gt-sm>
              <b>Date Created: </b>{{ screener.createdDateUTC | date }}
            </div>
            <div fxHide.gt-xs>
              <b>Created By: </b> {{ screener.createdByFullName }}
            </div>

            <div>{{ screener.screenerDescription }}</div>
            <div></div>
            <div fxLayout="row" fxLayoutGap="10px" *ngIf="!screener.isDefault">
              <div>
                <button
                  [disabled]="isUser"
                  *ngIf="screener.createdByMe || isAdmin"
                  (click)="editScreener(screener)"
                  class="btn btn-primary"
                >
                  Edit
                </button>
              </div>
              <div fxFlex>
                <button
                  [disabled]="isUser"
                  *ngIf="screener.createdByMe || isAdmin"
                  (click)="deleteScreener(screener)"
                  class="btn btn-primary"
                >
                  Delete
                </button>
              </div>
              <div fxHide.gt-xs>
                <button
                  class="btn btn-primary"
                  (click)="load(screener)"
                  *ngIf="screener.runId !== vm.currentRunId"
                >
                  Load
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div fxFlex fxFlex.lt-lg="20px"></div>
</div>

<ng-template #spinner>
  <esg-simple-spinner
    fxLayout="row"
    fxLayoutAlign="center center"
  ></esg-simple-spinner>
</ng-template>
