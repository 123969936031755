<ul
  fxLayout="row wrap"
  fxLayout.lt-md="column"
  fxLayoutAlign="start center"
  fxLayoutAlign.lt-md="start start"
>
  <li
    *ngFor="let item of items"
    fxFlex="360px"
    fxFlex.lt-md
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
  >

    <esg-switch
      [state]="item.selected"
      (clicked)="itemSelected.emit(item)"
      [class.disabled]="!item.isSelectedAvailable"
      [isSelectedAvailable]="item.isSelectedAvailable"
      [knobWidthPixels]="21"
      knobColor="#fff"
      colorOn="#007FA7"
      colorOff="#979797"
      textColor="#fff"
      textOn="ON"
      textOff="OFF"
      [borderPixels]="3"
    ></esg-switch>
    <div class="purple-dot" [class.Hide_purple-dot]="!item.isIndustryDefault"></div>
    <div [class.disabled]="!item.isSelectedAvailable" fxFlex>
      {{
        "CURRENT_MODEL.GLOBAL.RISK_METRIC_ITEMS." + item.key + ".NAME"
          | translate
      }}
    </div>
  </li>
</ul>
