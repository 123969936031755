import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RunService } from "@wtw/platform/services";
import { BehaviorSubject, combineLatest, map } from "rxjs";
import { ActionLever } from "src/app/api/generated";

@Component({
  selector: "esg-offerings",
  templateUrl: "./offerings.component.html",
  styleUrls: ["./offerings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferingsComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private runService: RunService
  ) {}

  offeringsTranslationKey = "CURRENT_MODEL.PAGES.OFFERINGS.";
  themesKey = "CURRENT_MODEL.RUN_PAGES.";

  showDropdownMenuAction$ = new BehaviorSubject<boolean>(false);
  showMoreAction$ = new BehaviorSubject<boolean>(false);

  viewModel$ = combineLatest([
    this.translateService.get("CURRENT_MODEL.GLOBAL.ACTION_LEVERS"), // Get a full list of all the availabl action levers
    this.translateService.get("CURRENT_MODEL.PAGES.OFFERINGS.MIN_ACTION_LEVERS_COUNT"), // default count of action levers to show
    this.activatedRoute.queryParams, //offerings filter passed in ("IS_SERVICE" or "IS_RISKIQ")
    this.runService.activeRun,
    this.showDropdownMenuAction$, // User clicked to show dropdown mwnu on small screen
    this.showMoreAction$, // user clicked show more button
  ]).pipe(
    map(
      ([
        localisationActionLevers,
        defaultOfferingsCount,
        params,
        activeRun,
        showDropdownMenu,
        showMore,
      ]) => {

        const ENVIRONMENT = this.translateService.instant("CURRENT_MODEL.GLOBAL.ESG_PILLARS.ENVIRONMENT.NAME").toLowerCase();
        const SOCIAL = this.translateService.instant("CURRENT_MODEL.GLOBAL.ESG_PILLARS.SOCIAL.NAME").toLowerCase();
        const GOVERNANCE = this.translateService.instant("CURRENT_MODEL.GLOBAL.ESG_PILLARS.GOVERNANCE.NAME").toLowerCase();

        // Pick out the action levers list for each pillar
        const environmentActionLevers = (activeRun.data.riskOfferings
          .find((offering) => offering.key.toLowerCase() === ENVIRONMENT).actionLevers as ActionLever[])
          .map((lever) => {
            return { ...lever, IS_ENVIRONMENT: true };
          }) as Array<any>;
        
        const socialActionLevers = (activeRun.data.riskOfferings
          .find((offering) => offering.key.toLowerCase() === SOCIAL).actionLevers as ActionLever[])
          .map((lever) => {
            return { ...lever, IS_SOCIAL: true };
          }) as Array<any>;

        const governanceActionLevers = (activeRun.data.riskOfferings
          .find((offering) => offering.key.toLowerCase() === GOVERNANCE).actionLevers as ActionLever[])
          .map((lever) => {
            return { ...lever, IS_GOVERNANCE: true };
          }) as Array<any>;
          
          const allLevers = environmentActionLevers
            .concat(socialActionLevers)
            .concat(governanceActionLevers);

        // Look up our menu levers in the look up list in localisation and map localisation and filter
        var offerings = allLevers
          .map((d) => {
            return { 
              key: d.key, ...localisationActionLevers[d.key], IS_ENVIRONMENT: d.IS_ENVIRONMENT, IS_SOCIAL: d.IS_SOCIAL, IS_GOVERNANCE: d.IS_GOVERNANCE
            }; //append the translations data onto the offering (this contains filtering info)
          })
           .filter((d) => {
             if (params.filter) {
               return d[params.filter] == true;
             }

             return true;
           });

        const offeringsCount =  offerings.length;

        if (!showMore) {
          offerings = offerings.slice(0, defaultOfferingsCount);
        }

        var orderedThemes = (params['filter']) 
          ? activeRun.data.esgPillars
            .find((pillar) => pillar.key.toLowerCase() === params['filter'].toLowerCase().replace('is_', '')).themes
            .sort((a, b) => a.index - b.index)
            .map((theme) => {
              return this.translateService.instant(`${this.themesKey}${theme.key.replaceAll('_', '-').toUpperCase()}`);
            })
          : null;

        return {
          offerings,
          showDropdownMenu,
          totalCount: offeringsCount,
          fromPillarKey: params.fromPillar,
          fromThemeKey: params.fromTheme,
          defaultOfferingsCount,
          clientName: activeRun.data.benchmarkingSelections[0].clientName,
          isFiltered: params['filter'],
          orderedThemes: orderedThemes          
        };
      }
    )
  );

  toggleMenu() {
    this.showDropdownMenuAction$.next(!this.showDropdownMenuAction$.value);
  }

  closeMenu() {
    this.showDropdownMenuAction$.next(false);
  }

  seeMore() {
    this.showMoreAction$.next(true);
  }

  seeLess() {
    this.showMoreAction$.next(false);
  }
}
