import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { EsgAppInsightsService } from '../esg-app-insights-service';

// **********************************************
// Any navigation event or UI action should cause a "Run Completed" to be logged to AppInsights
// Add this resolver to any route that needs to raise a "RunComplete" event
// There is currently one exception to this rule which is the "Executive Summary" - navigating to this page should not fire an Insight
// Not sure whether this is the best way to achieve this (eg. could have injected a service which listens to route changes) 
// Something on the route itself looked like it may be easier to maintain though so resolver was chosen
// **********************************************
@Injectable({ providedIn: 'root' })
    export class AppInsightsRunCompleteResolver implements Resolve<any> {
   
    constructor(
        private _esgAppInsightsService: EsgAppInsightsService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this._esgAppInsightsService.markRunAsComplete();        
        return true;
    }
}


