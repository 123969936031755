<div fxLayout="column" fxFlex>
  <div fxFlex>
    <h2>{{ heading }}</h2>
    <p [innerHtml]="paragraph"></p>
    <ng-content></ng-content>
  </div>
  <div class="link" *ngIf="linkText">
    <a
      [routerLink]="linkLocation"
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutGap="8px"
    >
      <div>{{ linkText }}</div>
      <div class="icon-back-arrow-2 arrow-medium-blue"></div
    ></a>
  </div>
</div>
