import { CustomizeComponent } from "./pages/customize/customize.component";
import { Route, Routes } from "@angular/router";
import { NavigationAction, NavigationType } from "@wtw/platform/interfaces";
import { ExecutiveSummaryComponent } from "./pages/executive-summary/executive-summary.component";
import { ExecutiveSummaryDetailComponent } from "./pages/executive-summary-detail/executive-summary-detail.component";
import { MyPerformanceScoreComponent } from "./pages/executive-summary-detail/my-performance-score/my-performance-score.component";
import { ThemeScoringComponent } from "./pages/executive-summary-detail/theme-scoring/theme-scoring.component";
import { OfferingsComponent } from "./pages/offerings/offerings.component";
import { OfferingComponent } from "./pages/offerings/offering/offering.component";
import { ScreenerManagementComponent } from "./pages/screener-management/screener-management.component";
import { AppInsightsRunCompleteResolver } from "./services/resolvers/AppInsightsRunCompleteResolver";
import { CompaniesComparisonChartComponent } from "./pages/executive-summary-detail/companies-comparison-chart/companies-comparison-chart.component";

const esgRoutes = [
  {
    path: "environment",
    children: [
      { path: "climate-risk-and-resilience" },    
      { path: "circular-economy" },
      { path: "net-zero-transition-planning" },
      { path: "supply-chain" }
    ],
  },
  {
    path: "social",
    children: [
      { 
        path: "employee-safety-and-security" },
      { path: "justice-fairness-and-equality" },
      { path: "employability-and-social-mobility" },
      { path: "workforce-resilience-and-stability" }
    ],
  },
  {
    path: "governance",
    children: [
      { path: "board-governance-and-effectiveness" },
      { path: "sustainable-investments" },
      { path: "risk-transfer-and-mitigation" },
      { path: "disclosures-and-targets" }
    ],
  },
].map((pillar) => {
  return {
    path: pillar.path,
    displayTitle: pillar.path,
    component: ExecutiveSummaryDetailComponent,
    data: {
      canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
        return true;
      },
      isVisible: () => {
        return true;
      },
    },
    children: [
      {
        path: "",
        redirectTo: "my-performance-score",
        pathMatch: "full",
      },
      {
        resolve: [AppInsightsRunCompleteResolver],
        path: "my-performance-score",
        component: MyPerformanceScoreComponent,
        displayTitle: "My Performance Score",
        data: {
          disableCurrencyPopup: true,
          canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
            return true;
          },
          isVisible: () => {
            return false;
          },
        },
      },
      ...pillar.children.map((c) => {
        return {
          resolve: [AppInsightsRunCompleteResolver],
          path: c.path.toLowerCase(),
          component: ThemeScoringComponent,
          displayTitle: c.path.toLowerCase(),
          data: {
            disableCurrencyPopup: true,
            canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
              return true;
            },
            isVisible: () => {
              return false;
            },
          },
        } as Route;
      }),
      {
        resolve: [AppInsightsRunCompleteResolver],
        path: "companies-comparison",
        component: CompaniesComparisonChartComponent,
        data: {
          disableCurrencyPopup: true,
          canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
            return true;
          },
          isVisible: () => {
            return false;
          },
        },
      },     
    ] as Route[],
  };
}) as Route[];

export const RUN_PAGES: Routes = [
  {
    path: "executive-summary",
    data: {
      displayTitle: "executive-summary",
      disableCurrencyPopup: true,
      onForwardNavigation: <NavigationAction>{
        type: NavigationType.persist,
      },
      runProgress: false,
      menuIconImg: "executive-summary.svg",
      canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
        return true;
      },
      isVisible: () => {
        return true;
      },
    },
    children: [
      {
        path: "",
        component: ExecutiveSummaryComponent,
        data: {
          displayTitle: "executive-summary",
          onForwardNavigation: <NavigationAction>{
            type: NavigationType.persist,
          },
          disableCurrencyPopup: true,
          runProgress: false,
          canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
           return true;
          },
          isVisible: () => {
            return false;
          },
        },
      },
      ...esgRoutes,
    ],
  },
  {
    resolve: [AppInsightsRunCompleteResolver],
    path: "screener-management",
    component: ScreenerManagementComponent,
    data: {
      displayTitle: "screener-management",
      onForwardNavigation: <NavigationAction>{ type: NavigationType.persist },
      runProgress: false,
      canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
        return true;
      },
      isVisible: () => {
        return false;
      },
    },
  },
  {
    resolve: [AppInsightsRunCompleteResolver],
    path: "customize",
    component: CustomizeComponent,
    data: {
      disableCurrencyPopup: true,
      displayTitle: "customize",
      onForwardNavigation: <NavigationAction>{ type: NavigationType.persist },
      runProgress: false,
      menuIconImg: "customize-button.svg",
      canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
        return true;
      },
      isVisible: () => {
        return true;
      },
    },
  },
  {
    path: "offerings",
    component: OfferingsComponent,
    data: {
      displayTitle: "offerings",
      onForwardNavigation: <NavigationAction>{ type: NavigationType.persist },
      runProgress: false,
      menuIconImg: "action-levers-button.svg",
      canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
        return true;
      },
      isVisible: () => {
        return true;
      },
    },
    children: [
      {
        resolve: [AppInsightsRunCompleteResolver],
        path: "",
        component: OfferingComponent,
        data: {
          displayTitle: "offerings",
          onForwardNavigation: <NavigationAction>{
            type: NavigationType.persist,
          },
          runProgress: false,
          canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
            return true;
          },
          isVisible: () => {
            return false;
          },
        },
      },
      {
        resolve: [AppInsightsRunCompleteResolver],
        path: ":offering",
        component: OfferingComponent,
        data: {
          disableCurrencyPopup: true,
          onForwardNavigation: <NavigationAction>{
            type: NavigationType.persist,
          },
          runProgress: false,
          canActivate: (stage: string = "", activePages: {[ key: string]: any }) => {
            return true;
          },
          isVisible: () => {
            return false;
          },
        },
      },
    ],
  },
];
