<div *ngIf="showColorBar">
  <div class="color-bar" [ngStyle]="{ 'background-color': colorHex }"></div>

  <div class="title">{{ title }}</div>
</div>

<div class="score-circle">
  <esg-circle-chart
    [data]="[
      {
        values: EsgScoringDataUtilities.mapToRecommendationScore(score),
        maxValue: 100,
        colour: colorHex,
        secondaryColor: secondaryColorHex
      }
    ]"
    [options]="{
      lineWeight: 22,
      height: 100,
      primaryText: score.toString(),
      secondaryText: secondaryText
    }"
  ></esg-circle-chart>
</div>
<div class="quartile-label">
  {{ quartile }}
</div>

<esg-score-slider
  [percent]="benchmarkPercent"
  badColorHex="#D9D9D9"
  mediumColorHex="#8D8D8DE8"
  [goodColorHex]="colorHex"
></esg-score-slider>

<div class="benchmark-text">{{ benchmarkText }}</div>
