<ul fxLayout="row" fxLayoutAlign="center stretch">
  <li
    *ngFor="let tab of tabs"
    [routerLink]="tab.link"
    routerLinkActive="active"
    fxLayoutAlign="center center"
  >
    {{ tab.text }}
  </li>
</ul>
