<div class="marker-container">
  <div
    [ngStyle]="{
      'left.%': percent - percent * 0.01 * 15
    }"
    class="marker"
  >
    {{ percent }}
  </div>
</div>
<div
  class="bar"
  [ngStyle]="{
    'background-image':
      'linear-gradient(to right,' +
      badColorHex +
      ',' +
      mediumColorHex +
      ',' +
      goodColorHex +
      ')'
  }"
></div>
