<div class="canvas-container" fxLayout="column" fxLayoutAlign="center center">
  <canvas [style.height.px]="options?.height ?? defaultHeight" #chart></canvas>
</div>
<div
  class="circle-container"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="5px"
>

  <div class="primary-score-text">{{ data[0].values[0].score }}%</div>
  <div class="label1">{{ data[0].label1 }}</div>
  <div class="divider"></div>
  <div class="secondary-score-text">{{ data[0].values[1].score  }}%</div>
  <div class="label2">{{ data[0].label2 }}</div>
</div>
