import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, map, switchMap } from "rxjs";
import { BenchmarkingSelection } from "src/app/api/generated";
import { ReferenceDataService } from "src/app/services/reference-data.service";
import { ScreenersService } from "src/app/services/screeners-service";

@Component({
  selector: "esg-financials",
  templateUrl: "./financials.component.html",
  styleUrls: ["./financials.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialsComponent {
  @Input() benchmarkingSelection: BenchmarkingSelection;
  @Input() peerCompanyCount: number;

  constructor(
    private referenceDataService: ReferenceDataService,
    private screenerService: ScreenersService,
    private translateService: TranslateService
  ) {}

  financialsTranslateKey = "CURRENT_MODEL.PAGES.CUSTOMIZE.FINANCIALS_SECTION";

  viewModel$ = this.translateService
    .get("CURRENT_MODEL.GLOBAL.MESSAGES.RETRIEVING_REFERENCE_DATA")
    .pipe(
      switchMap((message: string) => {
        return combineLatest([
          this.referenceDataService.pics6Industries$,
          this.referenceDataService.employeeBuckets$,
          this.referenceDataService.revenueBuckets$,
        ]).uiSignal({
          debugInfo: message,
          uiLabel: message,
        });
      })
    )
    .pipe(
      map(([industries, employeeBuckets, revenueBuckets]) => {
        const all = {
          id: -1,
          description: "ALL",
        };

        return {
          industries,
          employeeBuckets: [all, ...employeeBuckets],
          revenueBuckets: [all, ...revenueBuckets],
        };
      })
    );

  change() {
    var selectValuesArray: {name: string, value: string | number}[] = new Array();
    selectValuesArray.push({name: 'industry', value: this.benchmarkingSelection.industryPICS6}); 
    selectValuesArray.push({name: 'employee', value: this.benchmarkingSelection.employeeBucketId}); 
    selectValuesArray.push({name: 'revenue', value: this.benchmarkingSelection.revenueBucketId});
    this.screenerService.financialsUpdated$.next(selectValuesArray);
  }
}

