<ul fxLayout="row" fxLayout.lt-sm="column">
  <li
    *ngFor="let pillar of pillars"
    (click)="pillarSelected.emit(pillar)"
    [class.selected]="pillar.selected"
  >
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center">
      <div>
        {{ pillar.text }}
      </div>
      <div class="circle" fxLayoutAlign="center center">
        {{ pillar.count }}
      </div>
    </div>
  </li>
</ul>
