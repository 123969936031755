<esg-next-previous fxHide.gt-sm></esg-next-previous>
<div *ngIf="viewModel$ | async as vm">
  <p
    class="description"
    [innerHTML]="
      translationKey + '.PARAGRAPH'
        | translate
          : {
              Month: moment(vm.latestPerformanceScore.date).format('MMMM'),
              Year: moment(vm.latestPerformanceScore.date).format('Y'),
              ClientName: vm.client.displayName,
              ClientScore: vm.latestPerformanceScore.clientScore,
              IndustryScore: vm.latestPerformanceScore.industryScore,
              Benchmark: vm.pillar.percentile,
              Percentile25: vm.latestPerformanceScore.industry25thPerc,
              Percentile75: vm.latestPerformanceScore.industry75thPerc
            }
    "
  ></p>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="heading" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="15px">
    <h2>{{ translationKey + ".NAME" | translate }}</h2>
    <esg-button-menu
      [items]="buttonMenuItems"
      (itemSelected)="switchGraph($event)"
    ></esg-button-menu>
  </div>

  <esg-performance-score
    *ngIf="vm.selectedGraphType === graphTypeEnum.score"
    [performanceScores]="vm.performanceScores"
    [options]="{
      xAxisTitle: translationKey + '.GRAPHS.SCORE.X_AXIS_TITLE' | translate,
      yAxisTitle: translationKey + '.GRAPHS.SCORE.Y_AXIS_TITLE' | translate,
      industryScoreColour: '#000000',
      clientScoreColour:
        'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' + vm.pillar.key + '.PRIMARY_COLOR'
        | translate,
      percentilePolygonColour:
        'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' + vm.pillar.key + '.SECONDARY_COLOR'
        | translate
    }"
  >
  </esg-performance-score>

  <esg-performance-benchmark
    *ngIf="vm.selectedGraphType === graphTypeEnum.benchmark"
    [performanceScores]="vm.performanceScores"
    [options]="{
      xAxisTitle:
        translationKey + '.GRAPHS.BENCHMARKING.X_AXIS_TITLE' | translate,
      yAxisTitle:
        translationKey + '.GRAPHS.BENCHMARKING.Y_AXIS_TITLE' | translate,
      bubbleColour:
        'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' + vm.pillar.key + '.PRIMARY_COLOR'
        | translate,
      latestScoreBubbleColour:
        'CURRENT_MODEL.GLOBAL.ESG_PILLARS.' + vm.pillar.key + '.SECONDARY_COLOR'
        | translate,
      bubbleValueColour: '#FFFFFF'
    }"
  ></esg-performance-benchmark>
</div>
