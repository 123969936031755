import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "esg-tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent {
  @Input() tabs: TabVM[];
}

export interface TabVM {
  text: string;
  link: string;
}
