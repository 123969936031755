import { RiskOffering } from './../../../api/generated/RiskOffering';
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ESGPillar } from "src/app/api/generated/ESGPillar";

@Component({
  selector: "esg-action-levers",
  templateUrl: "./action-levers.component.html",
  styleUrls: ["./action-levers.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionLeversComponent {
  @Input() pillars: ESGPillar[];
  @Input() riskOfferings: RiskOffering[];


  getTopTheme(pillarKey: string) : string
  {
    const topTheme = this.pillars
      .find((pillar) => pillar.key.toLowerCase() === pillarKey.toLowerCase())
      .themes.find((theme) => theme.index === 1)
      .key.toString();
    return topTheme.replace(new RegExp('_', 'g'), '-');
  }

  getTopLever(pillarKey:string) : string
  {
    const topLever = this.riskOfferings
      .find((offering) => offering.key.toLowerCase() === pillarKey.toLowerCase())
      .actionLevers[0].key;

    return topLever;
  }
}

