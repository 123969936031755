import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "esg-numbered-box",
  templateUrl: "./numbered-box.component.html",
  styleUrls: ["./numbered-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberedBoxComponent {
  @Input() number: string;
  @Input() title: string;
}
