import { element } from 'protractor';
import { MetricType } from './../../api/generated/MetricType';

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { RunService, UserService } from "@wtw/platform/services";
import { filter, isEqual } from "lodash";
import { BehaviorSubject, combineLatest, map, Observable, Subscription, switchMap } from "rxjs";
import { ESGScoring, MetricTypeItem } from "src/app/api/generated";
import { ButtonMenuItemVM } from "src/app/shared/components/button-menu/button-menu.component";
import { pillarVM } from "./pillar-selector/pillar-selector.component";
import { sourceVM } from "./source-selector/source-selector.component";
import { ScreenersService } from "src/app/services/screeners-service";
import { ComparisonCompany } from 'src/app/api/generated/ComparisonCompany';
import { ReferenceDataProxy } from "src/app/api/ReferenceDataController";

@Component({
  selector: "esg-customize",
  templateUrl: "./customize.component.html",
  styleUrls: ["./customize.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CustomizeComponent implements OnInit, OnDestroy {
  @Input() search: (term: string) => Observable<any>;
  
  constructor(
    private runService: RunService,
    private translateService: TranslateService,
    private router: Router,
    private screenerService: ScreenersService,
    private userService: UserService,
    private referenceDataProxy: ReferenceDataProxy
  ) {}

  private subscriptions$ = new Subscription();
  private clonedData: ESGScoring;
  private industry: string;
  private employee: number;
  private revenue: number;
  
  riskMetricsKey = "CURRENT_MODEL.GLOBAL.RISK_METRICS.";
  // metric source items to display
  private metricSourceItemFilter = [this.translateService.instant(this.riskMetricsKey + "WTW.NAME"), 
    this.translateService.instant(this.riskMetricsKey + "ANILINE.NAME"), this.translateService.instant(this.riskMetricsKey + "POLECAT.NAME"), 
    this.translateService.instant(this.riskMetricsKey + "MSCI.NAME")].map((item) => { return item.toUpperCase();});
  // metrics which need to be sub-grouped under WTW
  private wtwSubGroupedMetrics = [this.translateService.instant(this.riskMetricsKey + "WTW.NAME"), this.translateService.instant(this.riskMetricsKey + "WTWINTERNAL.NAME")];

  isUser = this.userService.currentUserInfo.role === 'User';
  selectPillarKeyAction$ = new BehaviorSubject<string>("ENVIRONMENT");
  selectSourceKeyAction$ = new BehaviorSubject<string>("MSCI");
  selectMetricTypeKeyAction$ = new BehaviorSubject<string>(null);
  metricItemToggledAction$ = new BehaviorSubject(null);

  selectIndustryKeyAction$ = new BehaviorSubject<string>(null);
  selectEmpKeyAction$ = new BehaviorSubject<number>(null);
  selectRevenueKeyAction$ = new BehaviorSubject<number>(null);
  screenerSettingsChanged$ = new BehaviorSubject<boolean>(true);


  ngOnInit(): void {
     this.subscriptions$.add(
       this.screenerService.screenerCreated$.subscribe((runId) => {
         this.router.navigate(["/run", runId]);
       })
     );
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

  viewModel$ = combineLatest([
    this.runService.activeRun,
    this.selectPillarKeyAction$,
    this.selectSourceKeyAction$,
    this.selectMetricTypeKeyAction$,
    this.metricItemToggledAction$,
    this.screenerService.financialsUpdated$,
    this.screenerService.comparisonCompaniesChanged$,
  ])
  .pipe(
    switchMap(([activeRun, selectedPillarKey, selectedSourceKey, selectMetricTypeKey, _, financialsUpdated, comparisonCompaniesChanged]) => {
      // If old run, comparison companies may not exist in the json structure yet so forcibly add a collection if not.
      // set initial state of observable if we have companies
      if(!activeRun.data.comparisonCompanies) {
        activeRun.data.comparisonCompanies = [] as ComparisonCompany[];
      }

      if (!this.clonedData) {
      CheckIfPVARMissing(activeRun);
        // activeRun.data.MetricTypes.filter( x => x.source === "WTWCLIMATE").
        this.clonedData = structuredClone(activeRun.data);
        this.screenerService.comparisonCompanies$.next(this.clonedData.comparisonCompanies);
      }
      
      this.clonedData.comparisonCompanies = [...this.screenerService.comparisonCompanies$.value];

      if (selectMetricTypeKey === null) {
        selectMetricTypeKey = activeRun.data.metricTypes?.filter(
          (mt) => mt.source === selectedSourceKey
        )[0].key;
      }

      this.screenerSettingsChanged$.next(
        !isEqual(activeRun.data.metricTypes, this.clonedData.metricTypes) ||
        !isEqual(activeRun.data.benchmarkingSelections[0], this.clonedData.benchmarkingSelections[0]) ||
        this.ComparisonCompaniesAreDifferent(this.clonedData, activeRun)
      )

      this.getFincialDetailsSelections(financialsUpdated, activeRun.data.benchmarkingSelections[0]);

      return this.referenceDataProxy.getPeerCompanyCount(this.industry, +this.revenue, +this.employee).pipe(
        map((peerCompanyCount) => {
          return { 
            peerCompanyCount: peerCompanyCount.data,
            benchmarkingSelection: this.clonedData.benchmarkingSelections[0],
            pillars: this.filterPillars(selectedPillarKey),
            sources: this.filteredSources(selectedSourceKey),
            metricTypes: this.filteredButtonMenuMetricTypes(selectedSourceKey, selectMetricTypeKey),
            items: this.filteredMetricTypeItems(selectMetricTypeKey, selectedPillarKey),
            showIndustryMessage: (this.clonedData.metricTypes
                ?.find((s) => s.key === selectMetricTypeKey).items
                ?.filter((i) => i.pillar === selectedPillarKey))
                ?.find((item) => item.isIndustryDefault===true),
            screenerChanged: !isEqual(activeRun.data.metricTypes, this.clonedData.metricTypes) ||
            !isEqual(
              activeRun.data.benchmarkingSelections[0],
              this.clonedData.benchmarkingSelections[0]
            )                
            }
        }
      ))
    })
  );

  selectPillar(key: string) {
    this.selectPillarKeyAction$.next(key);
  }

  selectSource(key: string) {
    this.selectSourceKeyAction$.next(key);
    this.selectMetricTypeKeyAction$.next(null);
  }

  selectMetricType(key: string) {
    this.selectMetricTypeKeyAction$.next(key);
  }

  toggleItem(metricTypeItem: MetricTypeItem) {
    metricTypeItem.selected = !metricTypeItem.selected;
    this.metricItemToggledAction$.next(metricTypeItem);
  }

  /// Detect whether we have a difference between the current comparison selection and that of the existing run.
  ComparisonCompaniesAreDifferent(clonedData, activeRun) : boolean {
    const comparisonCompaniesCount = (clonedData.comparisonCompanies?.length) ? clonedData.comparisonCompanies.length : 0;
    const activeRunComparisonCompanesCount = activeRun.data.comparisonCompanies.length;

    // We have not selections so therefore no changes
    if(comparisonCompaniesCount === 0) {
      return false;
    }
    // If count is different we have a change
    if (comparisonCompaniesCount !== activeRunComparisonCompanesCount) {
      return true;
    }
    // If count is same we need to check that the client ids are not identical
    if(comparisonCompaniesCount === activeRunComparisonCompanesCount) {
       const peerCompanyIds = clonedData.comparisonCompanies.map((company) => company.clientId);
       const clonedDataPeerCompanyIds = activeRun.data.comparisonCompanies.map((company) => company.clientId);
       return !isEqual(peerCompanyIds, clonedDataPeerCompanyIds)
    }
  }

  save() {
    this.screenerService.save(this.clonedData);
  }

  private filteredButtonMenuMetricTypes(selectedSourceKey: string, selectMetricTypeKey: string) : ButtonMenuItemVM[] {
    const buttonMenuItems = ((selectedSourceKey === this.translateService.instant(this.riskMetricsKey + "WTW.NAME"))
      ? this.clonedData.metricTypes?.filter((item) => this.wtwSubGroupedMetrics.includes(item.source))
      : this.clonedData.metricTypes?.filter((mt) => mt.source === selectedSourceKey)
    ).map((metricType) => {
      return {
        text: this.translateService.instant(this.riskMetricsKey + metricType.key + ".LONG_NAME"),
        key: metricType.key,
        selected: metricType.key === selectMetricTypeKey,
      } as ButtonMenuItemVM;
    });
    return buttonMenuItems;
  }

  private filteredMetricTypeItems(selectMetricTypeKey: string, selectedPillarKey: string) : MetricTypeItem[] {
    return this.clonedData.metricTypes
    ?.find((s) => s.key === selectMetricTypeKey)
    .items.filter(
      (i) => i.pillar === selectedPillarKey
    ) as MetricTypeItem[]
  };

  private filterPillars(selectedPillarKey: string) : pillarVM[] {
    return this.clonedData.esgPillars
      ?.map((p) => p.key)
      .map((key) => {
        return {
          key,
          text: this.translateService.instant(
            "CURRENT_MODEL.GLOBAL.ESG_PILLARS." + key + ".NAME"
          ),
          selected: key === selectedPillarKey,
          count: this.clonedData.metricTypes
            .flatMap((mt) => mt.items)
            .filter((i) => i.pillar === key)
            .filter((i) => i.selected).length,
        } as pillarVM;
      })
  }

  private filteredSources(selectedSourceKey: string) : sourceVM[] {
    return this.clonedData.metricSources?.filter((item) => 
      this.metricSourceItemFilter.includes(item))?.map((source) => {
        return {
          key: source,
          text: this.translateService.instant(
            this.riskMetricsKey + source + ".NAME"
          ),
          selected: source === selectedSourceKey,
        } as sourceVM;
    })
  }

  private getFincialDetailsSelections(financialsUpdated, benchmarkingSelection) {
    if(financialsUpdated.length>0)
    {
      this.industry = financialsUpdated.find((item) => item.name === "industry").value.toString();
      this.employee = +financialsUpdated.find((item) => item.name === "employee").value;
      this.revenue = +financialsUpdated.find((item) => item.name === "revenue").value;
    }
    else {
      this.industry = benchmarkingSelection.industryPICS6;
      this.employee = benchmarkingSelection.employeeBucketId;
      this.revenue = benchmarkingSelection.revenueBucketId;
    }
  }
}
function CheckIfPVARMissing(activeRun) {
 if(activeRun.data.metricTypes.filter(x => x.source === "WTWINTERNAL").find(x => x.key === "WTWCLIMATE").items.find(x => x.key === "PVAR") === undefined)
  {
  // Add PTVAR to the metric types
    var element:any = {};// activeRun.data.metricTypes.filter(x => x.source === "WTWINTERNAL").find(x => x.key === "WTWCLIMATE").items[0] as MetricTypeItem;
    element.key = "PVAR";
    element.selected = false;
    element.isIndustryDefault = false;  
    element.isSelectedAvailable = false;
    element.pillar = "ENVIRONMENT";
    element.source = "WTWINTERNAL";
    activeRun.data.metricTypes.filter(x => x.source === "WTWINTERNAL").find(x => x.key === "WTWCLIMATE").items.push(element);

  }
}

